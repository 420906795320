import React from "react";
import tw from "twin.macro";
import { Button } from "@components/subscription/Button";
import Frame4 from "assets/images/frame4.png";

interface EnableDatarops {
  onNextStep: () => void;
  isClutch: boolean;
}

const Content = tw.div`flex justify-between flex-col gap-0 max-w-1200 mx-auto`;
const Container = tw.div`px-[5%]`;
const Title = tw.div`text-[60px] font-light leading-none mb-[8px] font-[300]`;
const SubTitle = tw.div`text-[28px] font-[400] mb-[64px] leading-none	mt-[20px]`;
const FlexContainer = tw.div`flex flex-col lg:flex-row justify-start items-start lg:gap-[72px] gap-[0]`;
const FlexItem = tw.div`flex w-full lg:w-[80%] flex-col`;
const ButtonContainer = tw.div`w-[240px]`;
const Image = tw.img`w-[350px]`;

export const EnableData: React.FC<EnableDatarops> = ({ onNextStep, isClutch }) => {
  return (
    <Container>
      <Content>
        <FlexContainer>
          <FlexItem>
            <Title>you can choose to enable 5G, if you want.</Title>
            <SubTitle>
              {isClutch
                ? `5G is faster, but can use up to 11% more battery life. if you’d like to change it on your REALLY Clutch SIM, go into Settings > Cellular > Cellular Data Options > Voice & Data and make sure 5G Auto is selected.`
                : `5G is faster, but can use up to 11% more battery life.
                    if you’d like to change it, go into Settings > Cellular > Cellular Data Options >
                    Voice & Data and make sure 5G Auto is selected.`}
            </SubTitle>
            <Image src={Frame4} />
            <ButtonContainer>
              <Button
                title="i’ve done this"
                type={"active"}
                isSubmit
                className="lg:w-full mt-[36px]"
                onClick={() => onNextStep()}
              />
            </ButtonContainer>
          </FlexItem>
        </FlexContainer>
      </Content>
    </Container>
  );
};
