import React, { useEffect, useCallback, useContext } from "react";
import tw from "twin.macro";
import { useIntercom } from "react-use-intercom";
import { Header } from "@really5g/ui-components";
import { useLocation } from "react-router-dom";
import { SubscriptionDataContext } from "@context/subscriptionContext";
import { stepPaths } from "@constants/subscription";

interface LayoutProps {
  children: React.ReactNode;
}

const Container = tw.div`min-h-screen bg-white text-black`;
const Main = tw.main``;

export const SubscriptionLayout: React.FC<LayoutProps> = ({ children }) => {
  const { boot } = useIntercom();
  const { pathname } = useLocation();
  const { navigation } = useContext(SubscriptionDataContext);

  const shouldShowBackButton = useCallback(() => {
    return stepPaths.includes(pathname);
  }, [pathname]);

  const onBackCallback = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  useEffect(() => {
    boot();
  }, []);

  return (
    <Container>
      <Header showBackButton={shouldShowBackButton()} backCallback={onBackCallback} />
      <Main>{children}</Main>
    </Container>
  );
};
