import React from "react";
import tw from "twin.macro";
import { Button } from "@components/subscription/Button";
import Frame7 from "assets/images/frame7.png";

interface FacetimeAppProps {
  onNextStep: () => void;
}

const Content = tw.div`flex justify-between flex-col gap-0 max-w-1200 mx-auto`;
const Container = tw.div`px-[5%]`;
const Title = tw.div`text-[60px] font-light leading-none mb-[8px] font-[300]`;
const SubTitle = tw.div`text-[28px] font-[400] mb-[64px] leading-none	mt-[20px]`;
const FlexContainer = tw.div`flex flex-col lg:flex-row justify-start items-start lg:gap-[72px] gap-[0]`;
const FlexItem = tw.div`flex w-full lg:w-[80%] flex-col`;
const ButtonContainer = tw.div`w-[240px]`;
const Image = tw.img`w-[350px]`;

export const Voicemail: React.FC<FacetimeAppProps> = ({ onNextStep }) => {
  return (
    <Container>
      <Content>
        <FlexContainer>
          <FlexItem>
            <Title>finally, let’s setup your voicemail (optional)</Title>
            <SubTitle>
              {`the final step to setup your voicemail is to select the Phone app,
              then the Voicemail tab at the bottom. There should be a ‘setup voicemail’
              button. Follow the instructions.`}
            </SubTitle>
            <Image src={Frame7} />
            <ButtonContainer>
              <Button
                title="complete setup"
                type={"active"}
                isSubmit
                className="lg:w-full mt-[36px]"
                onClick={() => onNextStep()}
              />
            </ButtonContainer>
          </FlexItem>
        </FlexContainer>
      </Content>
    </Container>
  );
};
