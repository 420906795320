import { useAuth } from "@context/authContext";
import { db } from "firebase";
import { doc, getDoc } from "firebase/firestore";
import { useLayoutEffect, useState } from "react";

const useShowPasswordModal = () => {
  const { user } = useAuth();
  const [show, setShow] = useState(false);

  useLayoutEffect(() => {
    getDoc(doc(db, "users", user!.uid)).then((userDoc) => {
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.needsPasswordUpdate) {
          return setShow(true);
        }
      }

      setShow(false);
    });
  }, [user]);

  return show;
};

export default useShowPasswordModal;
