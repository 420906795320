import tw from "twin.macro";
import { Button } from "@components/subscription/Button";
import { Spinner } from "@components/common/Spinner";
import { useStripeConfig } from "@hooks/useStripeConfig";
import { useCallback } from "react";

const Container = tw.div`flex h-[80vh] sm:h-full w-full items-center justify-center flex-col gap-10`;

const BillingDevices = () => {
  const { stripeConfigUrl, loading } = useStripeConfig();

  const openStripeUrl = useCallback(() => {
    if (stripeConfigUrl && typeof stripeConfigUrl === "string") {
      window.open(stripeConfigUrl, "_blank");
    }
  }, [stripeConfigUrl]);

  return (
    <Container>
      {loading ? (
        <Spinner color="white" />
      ) : (
        <Button title="go to billing configuration" onClick={openStripeUrl} />
      )}
    </Container>
  );
};

export default BillingDevices;
