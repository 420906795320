import React, { useEffect, useMemo } from "react";
import tw from "twin.macro";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/subscription/Button";

interface FacetimeAppProps {
  onSave: (id: number, payload: any) => void;
  lineIndex: number;
  onNextStep: () => void;
  totalLines?: number;
  setIsOnboardingComplete: (status: boolean) => void;
}

const Content = tw.div`flex justify-between flex-col gap-0 max-w-1200 mx-auto`;
const Container = tw.div`px-[5%]`;
const Title = tw.div`text-[60px] font-light leading-none mb-[8px] font-[300]`;
const SubTitle = tw.div`text-[28px] font-[400] leading-none	mt-[20px]`;
const FlexContainer = tw.div`flex flex-col lg:flex-row justify-start items-start lg:gap-[72px] gap-[0]`;
const FlexItem = tw.div`flex w-full lg:w-[80%] flex-col`;
const ButtonContainer = tw.div`lg:w-[340px] w-full`;

const goToDash = "go to your dashboard";
export const Success: React.FC<FacetimeAppProps> = ({
  onSave,
  lineIndex,
  onNextStep,
  totalLines,
  setIsOnboardingComplete,
}) => {
  const navigate = useNavigate();
  const lastLine = totalLines && lineIndex + 1 >= totalLines;

  const label = useMemo(() => {
    if (lastLine) return goToDash;
    return `continue to line ${lineIndex + 2} activation`;
  }, []);

  useEffect(() => {
    if (lastLine) {
      setIsOnboardingComplete(true);
    }
  }, []);

  return (
    <Container>
      <Content>
        <FlexContainer>
          <FlexItem>
            <Title>onboarding complete!</Title>
            <SubTitle>{`restart your phone to ensure optimal performance.`}</SubTitle>
            <SubTitle>
              also, we’ve sent you all the important information.
              {label === goToDash &&
                " if you’d like to check your usage or manage your account, you can login to your account below."}
            </SubTitle>
            <ButtonContainer>
              <Button
                title={label}
                type={"active"}
                isSubmit
                className="lg:w-full mt-[36px]"
                onClick={() => {
                  onSave(lineIndex, { activation_completed: true });

                  if (lastLine) {
                    navigate("/");
                    return;
                  }

                  onNextStep();
                }}
              />
            </ButtonContainer>
          </FlexItem>
        </FlexContainer>
      </Content>
    </Container>
  );
};
