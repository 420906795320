import { useAuth } from "@context/authContext";
import { handleApiError } from "@utils/errorHandler";
import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";
const AUTH_URL = import.meta.env.VITE_AUTH_URL;

// Hook to sync intercom session across devices
const useSyncIntercom = () => {
  const { boot } = useIntercom();
  const { user } = useAuth();

  useEffect(() => {
    const getHash = async () => {
      try {
        const idToken = await user!.getIdToken();
        const response = await fetch(`${AUTH_URL}/intercom/hash`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-jwt": idToken,
          },
        });

        if (!response.ok) {
          throw new Error(`Unauthorized ${response.status}`);
        }

        const { data: hash } = await response.json();
        boot({
          email: user!.email!,
          userHash: hash,
        });
      } catch (err) {
        handleApiError({
          err,
          message: "intercom/hash error",
          level: "warning",
        });
      }
    };

    getHash();
  }, []);
};

export default useSyncIntercom;
