import React, { useContext, useEffect, useState, useReducer, useCallback } from "react";
import tw from "twin.macro";
import { useNavigate, Navigate } from "react-router-dom";
import { SubscriptionDataContext, SubscriptionFlowData } from "@context/subscriptionContext";
import { SubscriptionLayout } from "@components/SubscriptionLayout";
import { Stepper } from "@components/subscription/Stepper";
import { reducer, createInitialLineItemsState, initialLineItems } from "@reducers/line-items";
import { useOrderInfo } from "@hooks/useOrderInfo";
import { PortNumber } from "./PortNumber";
import { Installation } from "./Installation";
import { Spinner } from "@components/common/Spinner";
import { LinesIndicator } from "@components/common/LineIndicator";
import { UnlockPhone } from "./UnlockPhone";
import { WifiConnection } from "./WifiConnection";
import { CLUTCH_PLAN_CODE } from "./Installation";
import { NavRoutes } from "@constants/routes";

const Container = tw.div`min-h-[100%] px-[5%]`;
const Content = tw.div`max-w-1200 mx-auto pt-[30px] pb-[150px]`;
const FlexContainer = tw.div`flex w-full items-center justify-center`;

enum Steps {
  PORT_NUMBER = "port-number",
  INSTALLATION = "installation",
}

const STEPS_MAP: { [key: number]: Steps } = {
  0: Steps.PORT_NUMBER,
  1: Steps.INSTALLATION,
};

const getStepName = (activeStep: number) => {
  return STEPS_MAP[activeStep];
};

const toggleStep = (currentStep: number, totalSteps: number = 2) => {
  return (currentStep + 1) % totalSteps;
};

export const Activation: React.FC = () => {
  const navigate = useNavigate();
  const { setGoBack, addData, data } = useContext(SubscriptionDataContext);
  const { loading, orderInfo } = useOrderInfo();
  const [activeLineIndex, setActiveLineIndex] = useState(0);
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);

  const currentLine = orderInfo?.lines?.[activeLineIndex];
  const shouldPortNumber = currentLine?.port_number !== "";
  const [activeStep, setActiveStep] = useState(0);
  const [phoneUnlocked, setPhoneUnlocked] = useState(data?.skipPortStep);
  const [wifiConnection, setWifiConnection] = useState(true);
  const isClutch = currentLine?.plan?.code === CLUTCH_PLAN_CODE;
  const lastLine = orderInfo && activeLineIndex + 1 >= (orderInfo?.lines?.length || 0);
  const lastStep = lastLine && isOnboardingComplete;

  const [, dispatch] = useReducer(reducer, initialLineItems, createInitialLineItemsState);

  const handleNextStep = (payload: any) => {
    if (shouldPortNumber && payload.ported_completed) {
      setActiveStep(1);
      return;
    }

    if (payload.activation_completed) {
      setActiveStep(orderInfo?.lines?.[activeLineIndex + 1]?.port_number ? 0 : 1);
      setActiveLineIndex((index) => index + 1);
      return;
    }
  };

  const updateLineItem = async (id: number, payload: SubscriptionFlowData): Promise<void> => {
    dispatch({
      id: `item_${id}`,
      type: "set_item",
      payload,
    });

    handleNextStep(payload);
  };

  const handleSkipUnlock = useCallback(() => {
    addData({ skipUnlockStep: true });
    navigate(`/${NavRoutes.Dashboard}`);
  }, []);

  const handleSkipPorting = useCallback(() => {
    addData({ skipPortStep: true });
    navigate(`/${NavRoutes.Dashboard}`);
  }, []);

  useEffect(() => {
    if (STEPS_MAP[activeStep] === Steps.PORT_NUMBER) {
      setGoBack(() => navigate(-1));
      return;
    }

    setGoBack(() => {
      setActiveStep((step) => toggleStep(step));
    });
  }, [activeStep]);

  useEffect(() => {
    if (!loading && currentLine) {
      setActiveStep(shouldPortNumber ? 0 : 1);
    }
  }, [loading]);

  if (orderInfo?.lines?.length === 0) return <Navigate to="/" />;

  const toggleUnlockScreen = () => {
    addData({ skipUnlockStep: false });
    setPhoneUnlocked(true);
    setWifiConnection(false);
  };

  const toggleWifiConnection = () => {
    setWifiConnection(true);
  };

  if (!phoneUnlocked && !isClutch && orderInfo?.lines) {
    return (
      <UnlockPhone
        orderInfo={orderInfo?.lines.length || 0}
        currentLine={currentLine}
        nextStep={toggleUnlockScreen}
        activeLineIndex={activeLineIndex}
        handleSkipUnlock={handleSkipUnlock}
      />
    );
  }

  if (!wifiConnection && !isClutch && orderInfo?.lines) {
    return (
      <WifiConnection
        orderInfo={orderInfo?.lines.length || 0}
        currentLine={currentLine}
        nextStep={toggleWifiConnection}
        activeLineIndex={activeLineIndex}
      />
    );
  }

  return (
    <SubscriptionLayout>
      <Stepper isComplete={lastStep} />
      <Container>
        <Content>
          {loading && !currentLine ? (
            <FlexContainer>
              <Spinner />
            </FlexContainer>
          ) : (
            <>
              <LinesIndicator
                currentLine={currentLine}
                orders={orderInfo?.lines.length || 0}
                activeLineIndex={activeLineIndex}
              />
              {getStepName(activeStep) === Steps.PORT_NUMBER && (
                <PortNumber
                  onSave={updateLineItem}
                  lineIndex={activeLineIndex}
                  currentLine={currentLine!}
                  handleSkipPorting={handleSkipPorting}
                />
              )}
              {getStepName(activeStep) === Steps.INSTALLATION && (
                <Installation
                  onSave={updateLineItem}
                  lineIndex={activeLineIndex}
                  totalLines={orderInfo?.lines.length}
                  currentLine={currentLine!}
                  setIsOnboardingComplete={setIsOnboardingComplete}
                />
              )}
            </>
          )}
        </Content>
      </Container>
    </SubscriptionLayout>
  );
};
