import { createContext } from "react";

export interface SubscriptionFlowData {
  activeLines: any;
  phoneBrand: string;
  phoneModel: string;
  accountNumber: string;
  transferPin: string;
  carrier: string;
  zipcode: string;
  planId: string;
  isPhoneUnlocked: boolean;
  isPortedNumber?: boolean;
  portedNumber?: string;
  first_name: string;
  last_name: string;
  user_email: string;
  user_password: string;
  user_phone: string;
  user_address: string;
  user_address2: string;
  user_city: string;
  user_state: string;
  user_zipcode: string;
  user_radio_space_available: boolean;
  user_high_speed_internet: boolean;
  imei: string;
  lines: any;
  numberOfLines: number;
  terms: boolean;
  skipUnlockStep: boolean;
  skipPortStep: boolean;
}

interface SubscriptionDataContextType {
  data: SubscriptionFlowData;
  addData: (data: any) => void;
  setGoBack: (fn: () => void) => void;
  navigation: {
    goBack: () => void;
  };
}

export const SubscriptionDataContext = createContext<SubscriptionDataContextType>({
  data: {
    activeLines: {},
    phoneBrand: "",
    phoneModel: "",
    planId: "",
    accountNumber: "",
    transferPin: "",
    zipcode: "",
    isPhoneUnlocked: false,
    isPortedNumber: undefined,
    portedNumber: undefined,
    carrier: "",
    first_name: "",
    last_name: "",
    user_email: "",
    user_password: "",
    user_phone: "",
    user_address: "",
    user_address2: "",
    user_city: "",
    user_state: "",
    user_zipcode: "",
    user_radio_space_available: false,
    user_high_speed_internet: false,
    imei: "",
    numberOfLines: 0,
    lines: [],
    terms: false,
    skipUnlockStep: false,
    skipPortStep: false,
  },
  addData: () => {},
  setGoBack: () => {},
  navigation: {
    goBack: () => null,
  },
});
