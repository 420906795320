import React, { useState, useCallback } from "react";
import tw from "twin.macro";
import { SubscriptionLayout } from "@components/SubscriptionLayout";
import { Checkbox } from "@components/subscription/Checkbox";
import { Button } from "@components/subscription/Button";
import { Stepper } from "@components/subscription/Stepper";
import { LinesIndicator } from "@components/common/LineIndicator";
import { Modal } from "@components/subscription/Modal";
import { SkipUnlock } from "../modals/SkipUnlock";
import Pin from "assets/images/pin.png";
import Instructions from "assets/images/unlock-phone.png";

interface UnlockPhoneProps {
  nextStep: () => void;
  currentLine: any;
  orderInfo: number;
  activeLineIndex: number;
  handleSkipUnlock: () => void;
}

const Content = tw.div`flex justify-between flex-col gap-0 max-w-1200 mx-auto`;
const Container = tw.div`px-[5%] mt-[30px] pb-[150px]`;
const Title = tw.div`text-[48px] font-light leading-none mb-[8px] font-[300]`;
const SubTitle = tw.div`text-[24px] font-[300] mt-[20px] leading-none`;
const List = tw.ul`text-[24px] font-[300] mt-[20px] mb-[20px] leading-none`;
const ListItem = tw.li`text-[24px] font-[300] leading-none mt-[5px]`;
const FlexContainer = tw.div`flex flex-col lg:flex-row justify-center items-start lg:gap-[72px] gap-[40px]`;
const FlexItem = tw.div`flex w-full flex-col`;
const ButtonContainer = tw.div`w-full`;
const GrayCard = tw.div`w-full bg-[rgba(217, 217, 217, 0.20)] p-[32px] font-[400]`;
const CardTitle = tw.div`text-[24px] mb-[20px]`;
const SmallText = tw.div`text-[15px] font-[400]`;
const LinksContainer = tw.div`text-[14px] font-[400]`;
const LinkList = tw.ul`mt-[20px]`;
const LinkListItem = tw.li`text-[15px] font-[400] leading-none mt-[15px]`;
const AnchorLink = tw.a`underline`;
const CardCircle = tw.div`rounded-full w-[56px] h-[56px] bg-[rgba(217, 217, 217, 0.70)] mb-[10px]`;
const CardImgContainer = tw.div`w-[full] mb-[20px] mt-[20px]`;
const Image = tw.img`w-full`;
const ButtonSeparator = tw.div`text-[24px] my-[36px] flex justify-center text-[400]`;

export const UnlockPhone: React.FC<UnlockPhoneProps> = ({
  nextStep,
  currentLine,
  orderInfo,
  activeLineIndex,
  handleSkipUnlock,
}) => {
  const [phoneUnlocked, setPhoneUnlocked] = useState(false);
  const [open, setOpen] = useState(false);

  const onCloseModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <SubscriptionLayout>
      <Stepper />
      <Container>
        <Content>
          <LinesIndicator
            currentLine={currentLine}
            orders={orderInfo || 0}
            activeLineIndex={activeLineIndex}
          />
          <FlexContainer>
            <FlexItem>
              <Title>confirm your phone is carrier unlocked</Title>
              <SubTitle>
                compatible unlocked device required. you will not be able to activate your Limitless
                plan if your device is locked. follow these steps to check:
              </SubTitle>
              <List>
                <ListItem>1. go to settings → general → about</ListItem>
                <ListItem>2. scroll to carrier lock</ListItem>
                <ListItem>3. if it says “no sim restrictions,” you’re good to go. </ListItem>
                <ListItem>
                  4. if it says “sim locked,” contact your current carrier to unlock your phone
                  first.
                </ListItem>
              </List>
              <Checkbox
                label="my phone is carrier unlocked"
                name="esim_installed"
                value={phoneUnlocked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPhoneUnlocked(e.target.checked);
                }}
              />
              <ButtonContainer>
                <Button
                  title="save & continue"
                  type={phoneUnlocked ? "active" : "disabled"}
                  isSubmit
                  className="lg:w-full mt-[36px]"
                  disabled={!phoneUnlocked}
                  onClick={() => nextStep()}
                />
                <ButtonSeparator>OR</ButtonSeparator>
                <Button
                  title="skip this for now"
                  type={"outline"}
                  isSubmit
                  className="w-full"
                  onClick={() => setOpen(true)}
                />
              </ButtonContainer>
            </FlexItem>
            <FlexItem>
              <GrayCard>
                <CardCircle>
                  <Image src={Pin} />
                </CardCircle>
                <CardTitle>how it should look:</CardTitle>
                <CardImgContainer>
                  <Image src={Instructions} />
                </CardImgContainer>
                <SmallText>helpful links</SmallText>
                <LinksContainer>
                  <LinkList>
                    <LinkListItem>
                      <AnchorLink href="https://www.att.com/deviceunlock/" target="_blank">
                        • unlock your phone or device - AT&T
                      </AnchorLink>
                    </LinkListItem>
                    <LinkListItem>
                      <AnchorLink
                        href="https://www.verizon.com/business/support/equipment-devices-services/wireless-devices/unlock-device/"
                        target="_blank"
                      >
                        • how to unlock wireless device - Verizon
                      </AnchorLink>
                    </LinkListItem>
                    <LinkListItem>
                      <AnchorLink
                        href="https://www.t-mobile.com/support/devices/unlock-your-mobile-wireless-device"
                        target="_blank"
                      >
                        • T-Mobile device unlock
                      </AnchorLink>
                    </LinkListItem>
                  </LinkList>
                </LinksContainer>
              </GrayCard>
            </FlexItem>
          </FlexContainer>
        </Content>
        <Modal
          Component={<SkipUnlock setOpen={setOpen} handleSkipUnlock={handleSkipUnlock} />}
          open={open}
          onCloseModal={onCloseModal}
        />
      </Container>
    </SubscriptionLayout>
  );
};
