import React from "react";

type Props = {
  size?: number;
  color?: string;
};

export const WarningIcon: React.FC<Props> = ({ size = 48 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
        fill="#FEF0C7"
      />
      <path
        d="M23.9998 21V25M23.9998 29H24.0098M22.6151 15.8917L14.3902 30.0983C13.934 30.8863 13.7059 31.2803 13.7396 31.6037C13.769 31.8857 13.9168 32.142 14.1461 32.3088C14.4091 32.5 14.8643 32.5 15.7749 32.5H32.2246C33.1352 32.5 33.5904 32.5 33.8534 32.3088C34.0827 32.142 34.2305 31.8857 34.2599 31.6037C34.2936 31.2803 34.0655 30.8863 33.6093 30.0983L25.3844 15.8917C24.9299 15.1065 24.7026 14.714 24.4061 14.5821C24.1474 14.4671 23.8521 14.4671 23.5935 14.5821C23.2969 14.714 23.0696 15.1065 22.6151 15.8917Z"
        stroke="#DC6803"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
