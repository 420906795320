import { CloseIcon } from "@assets/icons";
import { ExclamationMark } from "@assets/icons/ExclamationMark";
import { FC } from "react";
import tw from "twin.macro";

const Container = tw.div`relative flex-col rounded-xl bg-[#B42318] h-fit`;
const Body = tw.div`flex p-6 flex-col`;
const Title = tw.div`flex text-[24px]`;
const Message = tw.div`text-[14px]`;
const MessageContainer = tw.div`flex flex-col pl-[50px]`;
const IconContainers = tw.div`flex flex-row justify-between w-full`;
const Icon = tw.div`w-[38px] mr-[12px]`;
const CloseButton = tw.div`w-[20px] cursor-pointer`;
const TitleContainer = tw.div`flex flex-row justify-between items-center`;
const ButtonContainer = tw.div`w-[192px] mt-[15px]`;
const Link = tw.a`underline text-white text-[18px] font-bold cursor-pointer`;

interface Props {
  handleClose: (val: boolean) => void;
}

const SkipUnlockMessage: FC<Props> = ({ handleClose }) => {
  return (
    <Container>
      <Body>
        <IconContainers>
          <TitleContainer>
            <Icon>
              <ExclamationMark />
            </Icon>
            <Title>you haven’t activated your plan yet</Title>
          </TitleContainer>
          <CloseButton onClick={() => handleClose(false)}>
            <CloseIcon color="white" size="20" />
          </CloseButton>
        </IconContainers>
        <MessageContainer>
          <Message>
            please transfer your number to REALLY to activate your service and start enjoying all
            the benefits.
          </Message>
          <ButtonContainer>
            <Link href="/activation">activate my plan</Link>
          </ButtonContainer>
        </MessageContainer>
      </Body>
    </Container>
  );
};

export default SkipUnlockMessage;
