import React from "react";
import tw from "twin.macro";

const GrayCard = tw.div`w-full bg-[rgba(217, 217, 217, 0.20)] p-[32px] font-[400]`;
const CardTitle = tw.div`text-[24px] mb-[20px]`;
const CardText = tw.div`text-[16px] font-[400]`;

export const OtherInstructions: React.FC = () => {
  return (
    <GrayCard>
      <CardTitle>unlock instructions:</CardTitle>
      <CardText>
        you can typically find unlock instructions inside your account, or by a quick google search
        for unlock instructions by your carrier's name
      </CardText>
    </GrayCard>
  );
};
