import { SubscriptionFlowData } from "@context/subscriptionContext";

interface LineItemsState {
  [key: string]: SubscriptionFlowData;
}
interface Action {
  type: "set_item";
  id: string;
  payload: SubscriptionFlowData;
}

export const reducer = (state: LineItemsState, action: Action): LineItemsState => {
  switch (action.type) {
    case "set_item": {
      return {
        ...state,
        [action.id]: { ...state[action.id], ...action.payload },
      };
    }
  }

  throw Error("Unknown action: " + action.type);
};

export const createInitialLineItemsState = (): LineItemsState => {
  return {};
};

export const initialLineItems: LineItemsState = {};
