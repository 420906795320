import { useContext, useState } from "react";
import tw from "twin.macro";
import { Navigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { SubscriptionDataContext } from "@context/subscriptionContext";
import Explorer from "@components/Explorer";
import SetPasswordModal from "@components/SetPasswordModal";
import { ILine } from "@components/subscription/pages/Installation";
import ConciergeWidget from "@components/widgets/ConciergeWidget";
import UpcomingPaymentWidget from "@components/widgets/UpcomingPaymentWidget";
import YourDevicesWidget from "@components/widgets/YourDevicesWidget";
import { NavRoutes } from "@constants/routes";
import { useCustomerInfo } from "@hooks/useCustomerInfo";
import useShowPasswordModal from "@hooks/useShowPasswordModal";

import SkipUnlockMessage from "@components/alerts/SkipUnlockMessage";
import SkipPortingMessage from "@components/alerts/SkipPortingMessage";

const hasInactiveLine = (line: ILine) => !line.active;
const Container = tw.div`flex flex-col gap-4`;
const PageHeader = tw.p`text-5xl font-light leading-9 text-primary lowercase pb-8`;
const DashboardPage = () => {
  const { data: sessionData } = useContext(SubscriptionDataContext);
  const { show } = useIntercom();
  const { devices, upcomingPaymentDate, loading, data } = useCustomerInfo();
  const [showSkipUnlockMessage, setSkipUnlockMessage] = useState(sessionData?.skipUnlockStep);
  const [showSkipPortingMessage, setSkipPortingMessage] = useState(sessionData?.skipPortStep);

  const showPasswordModal = useShowPasswordModal();
  const skipActivationRedirect = sessionData?.skipUnlockStep || sessionData?.skipPortStep;

  // [ENG-637]
  if (data?.lines.some(hasInactiveLine) && !skipActivationRedirect)
    return <Navigate to={`/${NavRoutes.Activation}`} />;

  return (
    <Container>
      {showPasswordModal && <SetPasswordModal />}
      {/* TODO add account id */}
      <PageHeader>welcome back!</PageHeader>
      {showSkipUnlockMessage && <SkipUnlockMessage handleClose={setSkipUnlockMessage} />}
      {showSkipPortingMessage && <SkipPortingMessage handleClose={setSkipPortingMessage} />}
      <div className="grid grid-cols-layout-1 lg:grid-cols-layout-2 desktop:grid-cols-layout-3 gap-4">
        <ConciergeWidget sendMessageClicked={() => show()} />
        <YourDevicesWidget loading={loading} items={devices} />
        <div tw="[grid-column: 1 / -1] desktop:[grid-column: unset]">
          <UpcomingPaymentWidget paymentDate={upcomingPaymentDate} loading={loading} />
        </div>
      </div>
      <Explorer />
      {/* <CommunityVibes /> */}
      {/* <RecentPosts postsToShow={3} /> */}
    </Container>
  );
};

export default DashboardPage;
