import { FC, useState } from "react";
import tw from "twin.macro";

const H1 = tw.h1`text-primary text-4xl leading-9 font-normal`;

const Container = tw.div`flex flex-col items-start gap-y-6 self-stretch`;
const FrameWrapper = tw.div`relative w-full h-[38rem] md:h-[45rem]`;
const Frame = tw.iframe`rounded-xl flex justify-center items-center w-full h-full`;
const Overlay = tw.div`absolute inset-0 z-10 cursor-pointer`;

const Explorer: FC = () => {
  const [focused, setFocused] = useState(false);

  return (
    <Container>
      <H1>coverage map</H1>
      <FrameWrapper onClick={() => setFocused(true)}>
        {!focused && <Overlay />}
        <Frame src="https://explorer.really.com?preview=true" loading="eager" />
      </FrameWrapper>
    </Container>
  );
};

export default Explorer;
