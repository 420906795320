import { useCallback, useState } from "react";
import { getPortInStatus, postPortIn, updatePortIn } from "@api/index";
import { handleApiError } from "@utils/errorHandler";

export interface AccountInfo {
  account_number: string;
  carrier: string;
  enrollment_id: string;
  pin: string;
  zip_code: string;
}

export interface PortInResponse {
  description?: string;
  enrollment_id: string;
  status: string;
  status_code: string;
}

interface ReturnType {
  loading: boolean;
  portIn: (portData: any) => Promise<PortInResponse | undefined>;
  checkStatus: (enrollment_id: string) => Promise<string | undefined>;
  retryPortIn: (portData: any) => Promise<PortInResponse | undefined>;
  unhandledError: any | string;
  status: string;
  portInStatus: string;
  retryPortInStatus: string;
  clearStatus: () => void;
}

export const usePortIn = (): ReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const [unhandledError, setUnhandledError] = useState();
  const [status, setStatus] = useState("");
  const [portInStatus, setPortInStatus] = useState("");
  const [retryPortInStatus, setRetryPortInStatus] = useState("");

  const portIn = useCallback(
    async (portData: any) => {
      setLoading(true);

      const payload = {
        account_number: portData?.accountNumber,
        carrier: portData?.carrier.value,
        enrollment_id: portData?.enrollment_id,
        pin: portData?.transferPin,
        zip_code: portData?.zipcode,
      };

      try {
        const portInData = await postPortIn(payload);
        setLoading(false);
        setPortInStatus(portInData.status);

        return portInData;
      } catch (err: any) {
        handleApiError({ err, message: "postPortIn error", payload });
        setPortInStatus(err?.response?.data?.status);
        setUnhandledError(err);
        setLoading(false);
      }

      setLoading(false);
    },
    [setLoading, setUnhandledError, portInStatus],
  );

  const checkStatus = useCallback(
    async (enrollment_id: string) => {
      setLoading(true);

      try {
        const { status } = await getPortInStatus(enrollment_id);
        setLoading(false);
        setStatus(status);
        return status;
      } catch (err: any) {
        handleApiError({
          err,
          level: "warning",
          message: "postPortIn error",
          payload: { enrollment_id },
        });
        setLoading(false);
        setStatus("Error");
        setUnhandledError(err);
      }

      setLoading(false);
    },
    [setLoading, setUnhandledError],
  );

  const retryPortIn = useCallback(
    async (formData: any) => {
      setLoading(true);

      const payload = {
        account_number: formData?.accountNumber,
        carrier: formData?.carrier.value,
        enrollment_id: formData?.enrollment_id,
        zip_code: formData?.zipcode,
        pin: formData?.transferPin,
        // esn: "string",
        // mdn: "string",
      };

      try {
        const portInData = await updatePortIn(payload);
        setLoading(false);
        setRetryPortInStatus(portInData?.status);

        return portInData;
      } catch (err: any) {
        handleApiError({
          err,
          level: "warning",
          message: "updatePortIn error",
          payload,
        });
        setLoading(false);
        setUnhandledError(err);
      }
    },
    [setLoading, setUnhandledError],
  );

  const clearStatus = useCallback(() => {
    setStatus("");
    setPortInStatus("");
    setRetryPortInStatus("");
  }, [status, setStatus]);

  return {
    loading,
    portIn,
    retryPortIn,
    unhandledError,
    checkStatus,
    status,
    portInStatus,
    retryPortInStatus,
    clearStatus,
  };
};
