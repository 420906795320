import React, { useCallback, useEffect, useRef } from "react";
import tw from "twin.macro";
import { Button } from "@components/subscription/Button";
import { ClockIcon } from "@assets/icons/ClockIcon";
import { CheckIcon } from "@assets/icons/CheckIcon";
import { ExclamationIcon } from "@assets/icons/ExclamationIcon";
import { Spinner } from "@components/common/Spinner";
import { hasError } from "../pages/PortNumber";
import { UpdateLineResponse } from "@hooks/useUpdateLine";

interface TransferingPhoneProps {
  checkActivationStatus: (enrollment_id: string) => Promise<UpdateLineResponse | undefined>;
  enrollmentId: string;
  onSuccess: () => void;
  onFailed: () => void;
  status: string;
  activateLineStatus: string;
  activationError: string;
  loading: boolean;
  intervalRef: any;
  isClutch: boolean;
}

const Container = tw.div``;
const ModalTitle = tw.h1`text-black text-[32px] font-light leading-none break-words mb-5 mt-[60px]`;
const Text = tw.p`text-black`;
const ErrorText = tw.p`text-black mb-[20px]`;
const IconContainer = tw.div`absolute top-[-62px]`;
const SpinnerContainer = tw.div`flex justify-center items-center my-[60px]`;

const CHECK_STATUS_INTERVAL = 10000;
const ERROR_ATTEMPTS = 2;

export const ActivationStatus: React.FC<TransferingPhoneProps> = ({
  checkActivationStatus,
  enrollmentId,
  onSuccess,
  onFailed,
  status,
  activateLineStatus,
  activationError,
  loading,
  intervalRef,
  isClutch,
}) => {
  const errorStatus = [status, activateLineStatus].some(hasError);
  const errorStatusAttempts = useRef(0);

  const SIM = isClutch ? "eSIM" : "SIM";

  const handleClearInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  }, [intervalRef]);

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        checkActivationStatus(enrollmentId);

        if (errorStatus) {
          errorStatusAttempts.current += 1;
        }

        if (status === "Success" || errorStatusAttempts.current >= ERROR_ATTEMPTS) {
          handleClearInterval();
        }
      }, CHECK_STATUS_INTERVAL) as unknown as number;
    }

    if (status === "Success") {
      handleClearInterval();
    }

    return () => handleClearInterval();
  }, [status, errorStatus, enrollmentId]);

  if (status === "Success") {
    return (
      <Container>
        <IconContainer>
          <CheckIcon />
        </IconContainer>
        <ModalTitle>activation successful</ModalTitle>
        <Text>
          your {SIM} has been activated! you can start using the REALLY network once your {SIM} is
          installed
        </Text>
        <Button
          title={`install your ${SIM}`}
          type="active"
          isSubmit
          className="lg:w-full mt-[36px]"
          onClick={onSuccess}
          loading={loading}
        />
      </Container>
    );
  }

  if (errorStatus && errorStatusAttempts.current === ERROR_ATTEMPTS) {
    return (
      <Container>
        <IconContainer>
          <ExclamationIcon />
        </IconContainer>
        <ModalTitle>unfortunately, the activation failed</ModalTitle>
        <ErrorText>{activationError}</ErrorText>
        <Text>
          please reach out and we can help you activate your {SIM}. call us at 1-888-495-6120 or
          email us at support@really.com
        </Text>
        <Button
          title="try again"
          type="active"
          isSubmit
          className="lg:w-full mt-[36px]"
          onClick={onFailed}
          loading={loading}
        />
      </Container>
    );
  }

  return (
    <Container>
      <IconContainer>
        <ClockIcon />
      </IconContainer>
      <ModalTitle>we’re activating your {SIM}</ModalTitle>
      <Text>
        this process is usually pretty quick, but can take up to 1 minute. please stay on this page
        until complete
      </Text>
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    </Container>
  );
};
