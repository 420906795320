import React, { useCallback, useState, useContext, useRef } from "react";
import tw from "twin.macro";
import { Form } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { SubscriptionDataContext, SubscriptionFlowData } from "@context/subscriptionContext";
import { Button } from "@components/subscription/Button";
import { useUpdateLine } from "@hooks/useUpdateLine";
import { ILine } from "@components/subscription/pages/Installation";
import { Modal } from "@components/subscription/Modal";
import { ActivationStatus } from "@components/subscription/modals/ActicationStatus";
import { InputField } from "@components/subscription/InputField";
import { CLUTCH_PLAN_CODE } from "@components/subscription/pages/Installation";

interface SimInstallationProps {
  currentLine: ILine;
  setActiveSim: (val: boolean) => void;
}

const Title = tw.div`text-5xl font-light leading-none mb-[8px]`;
const SubTitle = tw.div`text-[28px] font-[400] leading-none`;
const FlexContainer = tw.div`flex flex-col lg:flex-row justify-center items-start lg:gap-[72px] gap-[20px]`;
const FlexItem = tw.div`flex w-full flex-col`;
const ButtonContainer = tw.div`w-[240px]`;
const InputContainer = tw.div`mt-[32px] mb-[32px]`;

export const SimActivation: React.FC<SimInstallationProps> = ({ setActiveSim, currentLine }) => {
  const {
    loading,
    updateActiveLine,
    activateLineStatus,
    checkActivationStatus,
    activationStatus,
    activationError,
  } = useUpdateLine();
  const [open, setOpen] = useState(false);
  const [formPayload, setFormPayload] = useState({ zipcode: "" });
  const { addData } = useContext(SubscriptionDataContext);
  const checkStatusInterval = useRef<number | undefined>();
  const isClutch = currentLine?.plan?.code === CLUTCH_PLAN_CODE;

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<Partial<SubscriptionFlowData>>({
    defaultValues: {
      zipcode: currentLine?.zip_code || "",
    },
  });

  const onCloseModal = useCallback(() => {
    setOpen(false);
    clearInterval(checkStatusInterval.current);
    checkStatusInterval.current = undefined;
  }, [setOpen]);

  const handleOnSuccess = useCallback(() => {
    setActiveSim(true);
    addData({
      activeLines: {
        [currentLine.enrollment_id]: true,
      },
    });
  }, []);

  const handleOnFailed = useCallback(async () => {
    setOpen(false);
  }, [formPayload, currentLine?.enrollment_id]);

  const onSubmit = async (formData: any) => {
    setFormPayload(formData);
    setOpen(true);

    if (currentLine?.enrollment_id && formData?.zipcode) {
      await updateActiveLine(currentLine.enrollment_id, formData.zipcode);
    }
  };

  return (
    <FlexContainer>
      <FlexItem>
        <Title>activate your eSIM now</Title>
        <SubTitle>the activation process can take at least one minute.</SubTitle>
        <Form method="post" onSubmit={handleSubmit(onSubmit)} className="w-full">
          <Controller
            name="zipcode"
            control={control}
            rules={{
              required: true,
            }}
            render={(form) => {
              const { field } = form;
              const { onChange, value } = field;

              return (
                <InputContainer>
                  <InputField
                    label="what is the billing zip code on the account?"
                    register={register}
                    name="zipcode"
                    required
                    error={errors.zipcode}
                    type="text"
                    value={value}
                    className={"!h-[64px]"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(e.target.value);
                      addData({ zipcode: e.target.value });
                    }}
                  />
                </InputContainer>
              );
            }}
          />
          <ButtonContainer>
            <Button
              title="save & continue"
              type="active"
              isSubmit
              className="lg:w-full mt-[36px]"
            />
          </ButtonContainer>
        </Form>
      </FlexItem>
      <FlexItem />
      <Modal
        Component={
          <ActivationStatus
            checkActivationStatus={checkActivationStatus}
            enrollmentId={currentLine?.enrollment_id}
            onSuccess={handleOnSuccess}
            onFailed={handleOnFailed}
            status={activationStatus}
            activateLineStatus={activateLineStatus}
            activationError={activationError}
            loading={loading}
            intervalRef={checkStatusInterval}
            isClutch={isClutch}
          />
        }
        open={open}
        onCloseModal={onCloseModal}
      />
    </FlexContainer>
  );
};
