import tw from "twin.macro";
import { useForm } from "react-hook-form";
import { Spinner } from "@components/common/Spinner";

type ResetForm = {
  newPassword: string;
};

interface ResetPasswordFormProps {
  onSubmit: (data: { newPassword: string }) => void;
}

const Form = tw.form`flex flex-col items-center gap-4 self-stretch`;
const FormInput = tw.input`text-base font-medium leading-4 rounded-sm p-4 self-stretch text-black`;
const SubmitBtn = tw.button`bg-[#7151F0] rounded-sm py-4 px-7 text-lg leading-[1.125rem] self-stretch`;

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const { onSubmit } = props;
  const btnText = "reset password";
  const defaultValues: { newPassword: string } = { newPassword: "" };
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ResetForm>({ defaultValues });

  const submit = handleSubmit((data) => onSubmit(data));

  return (
    <Form onSubmit={submit}>
      <FormInput
        type="password"
        {...register("newPassword")}
        placeholder="enter your new password"
      />
      <SubmitBtn type="submit">{isSubmitting ? <Spinner color="white" /> : btnText}</SubmitBtn>
    </Form>
  );
};
