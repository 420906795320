import React from "react";

type Props = {
  size?: number;
  color?: string;
};

export const ToolsIcon: React.FC<Props> = ({ size = 24, color = "currentColor" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 21 20">
      <path
        d="M3.16797 6.66663L13.168 6.66663M13.168 6.66663C13.168 8.04734 14.2873 9.16663 15.668 9.16663C17.0487 9.16663 18.168 8.04734 18.168 6.66663C18.168 5.28591 17.0487 4.16663 15.668 4.16663C14.2873 4.16663 13.168 5.28591 13.168 6.66663ZM8.16797 13.3333L18.168 13.3333M8.16797 13.3333C8.16797 14.714 7.04868 15.8333 5.66797 15.8333C4.28726 15.8333 3.16797 14.714 3.16797 13.3333C3.16797 11.9526 4.28726 10.8333 5.66797 10.8333C7.04868 10.8333 8.16797 11.9526 8.16797 13.3333Z"
        strokeWidth={2}
        stroke={color}
      />
    </svg>
  );
};
