import { IntercomProvider } from "react-use-intercom";
import Layout from "@components/Layout";
import ComingSoon from "@components/pages/ComingSoon";
import DashboardPage from "@components/pages/DashboardPage";
import LoginPage from "@components/pages/LoginPage";
import ForgotPasswordPage from "@components/pages/ForgotPasswordPage";
import NotFoundPage from "@components/pages/NotFoundPage";
import { AuthProvider, useAuth } from "@context/authContext";
import { SubscriptionProvider } from "@context/subscriptionContextProvider";
import { NavRoutes } from "constants/routes";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ErrorFallback from "@components/ErrorFallback";
import { Activation } from "@components/subscription/pages/Activation";
import BillingDevices from "@components/pages/BillingDevices";
import { useLayoutEffect } from "react";
import EmailActionsPage from "@components/pages/EmailActionsPage";
import PrivacyAndSecurity from "@components/PrivacyAndSecurity";
import Support from "@components/pages/Support";

const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_ID;

const router = createBrowserRouter([
  {
    path: NavRoutes.Login,
    Component: LoginPage,
    ErrorBoundary: ErrorFallback,
  },
  {
    path: NavRoutes.ForgotPass,
    Component: ForgotPasswordPage,
    ErrorBoundary: ErrorFallback,
  },
  {
    path: NavRoutes.Actions,
    Component: EmailActionsPage,
    ErrorBoundary: ErrorFallback,
  },
  {
    id: "root",
    path: "/",
    element: (
      <RequireAuth>
        <Layout />
      </RequireAuth>
    ),
    ErrorBoundary: ErrorFallback,
    children: [
      {
        index: true, // Default component
        Component: DashboardPage,
        ErrorBoundary: ErrorFallback,
      },
      {
        path: NavRoutes.Dashboard,
        Component: DashboardPage,
        ErrorBoundary: ErrorFallback,
      },
      {
        path: NavRoutes.BillingDevices,
        Component: BillingDevices,
        ErrorBoundary: ErrorFallback,
      },
      {
        path: NavRoutes.PrivacySecurity,
        Component: PrivacyAndSecurity,
        ErrorBoundary: ErrorFallback,
      },
      {
        path: NavRoutes.Support,
        Component: Support,
        ErrorBoundary: ErrorFallback,
      },
      ...[
        NavRoutes.Community,
        NavRoutes.MemberBenefits,
        NavRoutes.Network,
        NavRoutes.Invites,
        NavRoutes.Settings,
      ].map((path) => ({
        path,
        Component: ComingSoon,
      })),
      {
        path: "*",
        Component: NotFoundPage,
      },
    ],
  },
  {
    id: "activation",
    path: NavRoutes.Activation,
    element: (
      <RequireAuth>
        <Activation />
      </RequireAuth>
    ),
    ErrorBoundary: ErrorFallback,
  },
]);

function RequireAuth({ children }: { children: JSX.Element }) {
  const { user, loginWithToken } = useAuth();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const autologin = searchParams.get("autologin");
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (autologin) {
      loginWithToken().catch(() => {
        navigate(`/${NavRoutes.Login}`);
      });
    }
  }, []);

  // null would work, but let's start with white fullscreen
  if (autologin && !user) return <div className="h-screen bg-white" />;
  if (!user) {
    // Redirect them to the /login page, but save the current location they were trying to go
    return <Navigate to={`/${NavRoutes.Login}`} state={{ from: pathname + search }} replace />;
  }

  return children;
}

function App() {
  return (
    <AuthProvider>
      <SubscriptionProvider>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
          <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
        </IntercomProvider>
      </SubscriptionProvider>
    </AuthProvider>
  );
}

export default App;
