import { EyeIcon } from "@assets/icons/EyeIcon";
import { EyeOffIcon } from "@assets/icons/EyeOffIcon";
import { Button } from "@components/subscription/Button";
import { InputField } from "@components/subscription/InputField";
import useYupValidationResolver from "@hooks/useYupValidationResolver";
import { useState } from "react";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import * as yup from "yup";
import { useAuth } from "@context/authContext";
import Modal from "react-responsive-modal";
import { LockIcon } from "@assets/icons";
import { FirebaseError } from "firebase/app";
import { captureException } from "@sentry/react";

const Container = tw.div`sm:min-w-[300px] md:min-w-[600px] flex flex-col p-2`;
const Label = tw.p`text-4xl font-light`;
const Description = tw.p`text-16 font-light text-gray-400 mt-1 mb-6`;
const IconContainer = tw.div`absolute top-[49px] right-[20px] cursor-pointer`;
const IconWrapper = tw.div`rounded-full p-4 bg-purple [&>*]:shrink-0 w-max mb-2`;
const InputWrapper = tw.div`relative`;
const Form = tw.form`w-auto flex flex-col`;

interface ChangePasswordForm {
  confirmPassword: string;
  newPassword: string;
}

const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, "password must be at least 8 characters")
    .required("this field is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null as any], "passwords must match")
    .required("this field is required"),
});

function camelCaseToSpaceSeparated(text: string) {
  return text.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
}

const SetPasswordModal = () => {
  const [open, setOpen] = useState(true);
  const { changePassword, loginWithToken, updateNeedsPasswordUpdate } = useAuth();
  const [inputTypes, setInputTypes] = useState({
    newPassword: "password",
    confirmPassword: "password",
  });
  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordForm>({ resolver });

  const onSubmit = async (data: ChangePasswordForm) => {
    try {
      await changePassword({ newPassword: data.newPassword });
    } catch (err) {
      if (err instanceof FirebaseError && err.code === "auth/requires-recent-login") {
        try {
          // Retry to reauthenticate user & change password
          await loginWithToken();
          await changePassword({ newPassword: data.newPassword });
        } catch (reauthErr) {
          captureException(err, {
            level: "error",
            extra: { message: "reauthenticate & changePassword error" },
          });
        }
        return;
      }

      captureException(err, {
        level: "error",
        extra: { message: "changePassword error" },
      });
    } finally {
      await updateNeedsPasswordUpdate();
      setOpen(false);
    }
  };

  const toggleInputType = (name: "confirmPassword" | "newPassword") => {
    setInputTypes((prevState) => ({
      ...prevState,
      [name]: prevState[name] === "password" ? "text" : "password",
    }));
  };

  const renderEyeIcon = (name: "newPassword" | "confirmPassword", hasError?: string) => (
    <IconContainer style={{ right: hasError ? 50 : 20 }} onClick={() => toggleInputType(name)}>
      {inputTypes[name] === "password" ? <EyeIcon /> : <EyeOffIcon />}
    </IconContainer>
  );

  const renderInput = (name: "confirmPassword" | "newPassword") => (
    <InputWrapper key={name}>
      <InputField
        register={register}
        label={camelCaseToSpaceSeparated(name)}
        name={name}
        type={inputTypes[name]}
        className={"!h-[64px] bg-[#333333]"}
        error={errors[name]}
      />
      {renderEyeIcon(name, errors[name]?.message)}
    </InputWrapper>
  );
  return (
    <Modal
      open={open}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      center
      styles={{
        modal: {
          borderRadius: 4,
          padding: "40px 32px",
          background: "#1a1a1a",
        },
      }}
      onClose={() => setOpen(false)}
    >
      <Container>
        <IconWrapper>
          <LockIcon color="white" />
        </IconWrapper>
        <Label>set your password</Label>
        <Description>to access your dashboard, please create a secure password.</Description>
        <Form>
          {renderInput("newPassword")}
          {renderInput("confirmPassword")}
          <Button
            loading={isSubmitting}
            type="purple"
            className="!w-full mt-4"
            title="save & continue"
            onClick={handleSubmit(onSubmit)}
          />
        </Form>
      </Container>
    </Modal>
  );
};

export default SetPasswordModal;
