import { useCallback } from "react";
import { Login } from "@really5g/ui-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@context/authContext";

const LoginPage = () => {
  const navigate = useNavigate();
  const { user, loginWithGoogle, loginWithPassword } = useAuth();
  const { search, state } = useLocation();

  const navigateToRedirectedUrl = useCallback(() => {
    // Send them back to the page they tried to visit when they were redirected to the login page
    const from = state?.from || "/";
    navigate(from, { replace: true });
  }, [search, navigate]);

  return (
    <Login
      signInWithGoogle={loginWithGoogle}
      isUserLoggedIn={!!user}
      onSubmit={loginWithPassword}
      authCallback={navigateToRedirectedUrl}
    />
  );
};

export default LoginPage;
