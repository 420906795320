import { useState, useEffect, useRef } from "react";

function useComponentWidth(): [any, number] {
  const [width, setWidth] = useState(0);
  const ref = useRef();

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (entries[0]) {
        setWidth(entries[0].contentRect.width);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, []);

  return [ref, width];
}

export default useComponentWidth;
