import { useCallback, useState } from "react";
import { updateActiveLineStatus, getActivationStatus } from "@api/index";
import { handleApiError } from "@utils/errorHandler";

export interface UpdateLinePayload {
  enrollment_id: string;
  zip_code: string;
}

export interface UpdateLineResponse {
  enrollment_id: string;
  active: boolean;
  status: string;
}

interface ReturnType {
  loading: boolean;
  updateActiveLine: (eid: string, zipcode: string) => Promise<UpdateLineResponse | undefined>;
  unhandledError: any | string;
  activateLineStatus: string;
  checkActivationStatus: (enrollment_id: string) => Promise<UpdateLineResponse | undefined>;
  activationStatus: string;
  activationError: string;
}

export const useUpdateLine = (): ReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const [unhandledError, setUnhandledError] = useState();
  const [activateLineStatus, setActivateLineStatus] = useState("");
  const [activationStatus, setActivationStatus] = useState("");
  const [activationError, setActivationError] = useState("");

  const updateActiveLine = useCallback(
    async (enrollmentId: string, zipcode: string) => {
      setLoading(true);
      // required to reset the status of the activation modals
      setActivationStatus("");
      const payload = {
        enrollment_id: enrollmentId,
        zip_code: zipcode,
      };
      try {
        const activeLineStatus = await updateActiveLineStatus(payload);
        setLoading(false);
        setActivateLineStatus(activeLineStatus.status);

        return activeLineStatus;
      } catch (err: any) {
        handleApiError({
          err,
          level: "error",
          message: "updateActiveLineStatus error",
          payload,
        });
        setUnhandledError(err);
      }

      setLoading(false);
    },
    [setLoading, setUnhandledError],
  );

  const checkActivationStatus = useCallback(
    async (enrollmentId: string) => {
      setLoading(true);

      try {
        const activationCheck = await getActivationStatus(enrollmentId);
        setLoading(false);

        if (activationCheck) {
          setActivationStatus(activationCheck.status);
          return activationCheck;
        }
      } catch (err: any) {
        handleApiError({ err, payload: { enrollmentId }, message: "getActivationStatus error" });
        setActivationStatus(err?.response?.data?.status);
        setActivationError(err?.response?.data?.error);
        setUnhandledError(err);
      }

      setLoading(false);
    },
    [setLoading, setUnhandledError],
  );

  return {
    loading,
    updateActiveLine,
    unhandledError,
    activateLineStatus,
    checkActivationStatus,
    activationStatus,
    activationError,
  };
};
