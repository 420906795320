import React from "react";
import tw from "twin.macro";
import { Button } from "@components/subscription/Button";
import data_roaming from "assets/images/data_roaming.png";

interface EnableRoamingProps {
  onNextStep: () => void;
  isClutch: boolean;
}

const Content = tw.div`flex justify-between flex-col gap-0 max-w-1200 mx-auto`;
const Container = tw.div`px-[5%]`;
const Title = tw.div`text-[60px] font-light leading-none mb-[8px] font-[300]`;
const SubTitle = tw.div`text-[28px] font-[400] mb-[64px] leading-none	mt-[20px]`;
const FlexContainer = tw.div`flex flex-col lg:flex-row justify-start items-start lg:gap-[72px] gap-[0]`;
const FlexItem = tw.div`flex w-full lg:w-[80%] flex-col`;
const ButtonContainer = tw.div`w-[240px]`;
const Image = tw.img`w-[350px]`;

export const EnableRoaming: React.FC<EnableRoamingProps> = ({ onNextStep, isClutch }) => {
  return (
    <Container>
      <Content>
        <FlexContainer>
          <FlexItem>
            <Title>now we need to turn data roaming on.</Title>
            <SubTitle>
              {isClutch
                ? `in order to have optimal coverage nationwide and internationally, data roaming must be turned on for your REALLY Clutch SIM. go into Settings > Cellular > Cellular Data Options and make sure ‘Data Roaming’ is enabled.`
                : `in order to have optimal coverage nationwide and internationally,
              data roaming must be turned on. go into Settings > Cellular > Cellular Data Options
              and make sure ‘Data Roaming’ is enabled.`}
            </SubTitle>
            <Image src={data_roaming} />
            <ButtonContainer>
              <Button
                title="i’ve done this"
                type={"active"}
                isSubmit
                className="lg:w-full mt-[36px]"
                onClick={() => onNextStep()}
              />
            </ButtonContainer>
          </FlexItem>
        </FlexContainer>
      </Content>
    </Container>
  );
};
