import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "firebase";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { NavRoutes } from "constants/routes";
import { useAuth } from "@context/authContext";

const useEmailActions = () => {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [actionMode, setActionMode] = useState("");

  const [isCodeValid, setIsCodeValid] = useState(false);
  const navigate = useNavigate();
  const { loginWithPassword } = useAuth();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get("mode");

    if (mode) {
      setActionMode(mode);
    }

    if (mode === "resetPassword") {
      const oobCode = queryParams.get("oobCode");

      if (!oobCode) {
        setMessage("Invalid or missing action code.");
        return;
      }

      verifyPasswordResetCode(auth, oobCode)
        .then((email) => {
          setResetPasswordEmail(email);
          setIsCodeValid(true);
        })
        .catch((error) => {
          setMessage(`Error: ${error.message}`);
          setIsCodeValid(false);
        });

      return;
    }
  }, []);

  const handleResetPassword = async ({ newPassword }: { newPassword: string }) => {
    const queryParams = new URLSearchParams(location.search);
    const oobCode = queryParams.get("oobCode");

    if (oobCode) {
      try {
        await confirmPasswordReset(auth, oobCode, newPassword);

        setMessage("Password has been reset successfully.");

        await loginWithPassword({ email: resetPasswordEmail, password: newPassword });

        navigate(`/${NavRoutes.Dashboard}`);
      } catch (error: any) {
        setMessage(`Error: ${error.message}`);
      }
    } else {
      setMessage("Invalid or missing action code.");
    }
  };

  return {
    message,
    isCodeValid,
    handleResetPassword,
    resetPasswordEmail,
    actionMode,
  };
};

export default useEmailActions;
