import React from "react";
import tw from "twin.macro";
import { Button } from "@components/subscription/Button";
import { WarningIcon } from "@assets/icons/WarningIcon";

interface SkipPortingProps {
  setOpen: (val: boolean) => void;
  handleSkipPorting: () => void;
}

const Container = tw.div``;
const ModalTitle = tw.h1`text-black text-[32px] font-light leading-none break-words mb-5 mt-[60px]`;
const Text = tw.p`text-black`;
const IconContainer = tw.div`absolute top-[-62px]`;
const ButtonContainer = tw.div`flex flex-col`;

export const SkipPorting: React.FC<SkipPortingProps> = ({ setOpen, handleSkipPorting }) => {
  return (
    <Container>
      <IconContainer>
        <WarningIcon />
      </IconContainer>
      <ModalTitle>are you sure?</ModalTitle>
      <Text>
        by skipping the number transfer step, your plan will not be active yet. don’t worry, we make
        it easy to come back and complete this step whenever you’re ready. plus, your billing cycle
        won’t start until you activate your plan.
      </Text>
      <ButtonContainer>
        <Button
          title="yes, skip this"
          type={"active"}
          isSubmit
          className="lg:w-full mt-[36px]"
          onClick={() => handleSkipPorting()}
        />
        <Button
          title="no, go back and check"
          type={"text"}
          isSubmit
          className="mt-[16px]"
          onClick={() => setOpen(false)}
        />
      </ButtonContainer>
    </Container>
  );
};
