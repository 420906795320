import React from "react";
import tw from "twin.macro";
import { SubscriptionLayout } from "@components/SubscriptionLayout";
import { Button } from "@components/subscription/Button";
import { Stepper } from "@components/subscription/Stepper";
import { LinesIndicator } from "@components/common/LineIndicator";
import { WifiSignal } from "@assets/icons/WifiSignal";

interface UnlockPhoneProps {
  nextStep: () => void;
  currentLine: any;
  orderInfo: number;
  activeLineIndex: number;
}

const Content = tw.div`flex justify-between flex-col gap-0 max-w-1200 mx-auto`;
const Container = tw.div`px-[5%] mt-[30px] pb-[150px]`;
const Title = tw.div`text-[48px] font-light leading-none mb-[8px] font-[300] mt-[20px]`;
const SubTitle = tw.div`text-[24px] font-[300] mt-[20px] leading-none`;
const FlexContainer = tw.div`flex flex-col lg:flex-row justify-center items-start lg:gap-[72px] gap-[40px]`;
const FlexItem = tw.div`flex w-full flex-col`;
const ButtonContainer = tw.div`w-[320px]`;

export const WifiConnection: React.FC<UnlockPhoneProps> = ({
  nextStep,
  currentLine,
  orderInfo,
  activeLineIndex,
}) => {
  return (
    <SubscriptionLayout>
      <Stepper />
      <Container>
        <Content>
          <LinesIndicator
            currentLine={currentLine}
            orders={orderInfo || 0}
            activeLineIndex={activeLineIndex}
          />
          <FlexContainer>
            <FlexItem>
              <WifiSignal />
              <Title>make sure you’re connected to wifi</Title>
              <SubTitle>
                before we transfer your number, we want to make sure you avoid losing service.
                keeping your phone connected to wifi will ensure that this goes smoothly.
              </SubTitle>
              <ButtonContainer>
                <Button
                  title="okay, i’m connected to wifi"
                  type={"active"}
                  isSubmit
                  className="w-full mt-[36px]"
                  onClick={() => nextStep()}
                />
              </ButtonContainer>
            </FlexItem>
            <FlexItem />
          </FlexContainer>
        </Content>
      </Container>
    </SubscriptionLayout>
  );
};
