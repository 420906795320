import React, { type ReactNode } from "react";
import { Modal as ResponsiveModal } from "react-responsive-modal";
import tw from "twin.macro";

interface ModalProps {
  Component: ReactNode;
  open: boolean;
  onCloseModal: () => void;
}

const ModalContainer = tw.div`m-2.5 mt-10 w-full max-w-[540px] break-words relative`;

export const Modal: React.FC<ModalProps> = ({ Component, open, onCloseModal }) => {
  return (
    <ResponsiveModal
      open={open}
      onClose={onCloseModal}
      center
      styles={{
        modal: {
          borderRadius: 4,
          padding: 20,
        },
      }}
    >
      <ModalContainer>{Component}</ModalContainer>
    </ResponsiveModal>
  );
};
