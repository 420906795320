import React from "react";
import tw from "twin.macro";
import defaultAvatar from "@assets/icons/AvatarIcon.svg";

const Container = tw.div`flex gap-3 pl-2 pt-3 pb-0 whitespace-nowrap`;
const Txt = tw.p`font-really text-sm leading-5 overflow-hidden text-ellipsis`;
const Name = tw(Txt)`font-bold`;
const Email = tw(Txt)`font-normal opacity-60`;
const TxtContainer = tw.div`flex flex-col justify-center w-[80%] sm:w-[90%]`;

type Props = {
  name: string;
  avatarUrl: string;
  email: string;
};

const UserImage = tw.img`bg-white rounded-full w-[43px]`;
const User: React.FC<Props> = ({ name, email, avatarUrl }) => {
  return (
    <Container>
      <UserImage src={avatarUrl || defaultAvatar} alt="user avatar" referrerPolicy="no-referrer" />
      <TxtContainer>
        {name && <Name>{name}</Name>}
        {email && <Email>{email}</Email>}
      </TxtContainer>
    </Container>
  );
};

export default User;
