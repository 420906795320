import React from "react";
import tw from "twin.macro";

interface Props {
  currentLine: any;
  activeLineIndex: number;
  orders: number
}

const Container = tw.div`flex w-full items-end justify-end px-[5%] pb-[10px] text-[14px]`;

export const LinesIndicator: React.FC<Props> = ({ currentLine, activeLineIndex, orders }) => (
  <Container>
    {currentLine?.enrollment_id || currentLine?.port_number} - {activeLineIndex + 1} /{" "}
    {orders}
  </Container>
);
