import { SeverityLevel, captureException } from "@sentry/react";

type Props = {
  err: any;
  payload?: any;
  message: string;
  level?: SeverityLevel;
};
export const handleApiError = ({ err, payload, message, level }: Props) => {
  const hasResponse = err?.response?.data;
  const errorMessage = hasResponse?.error || err;
  const errorPayload = payload ? JSON.stringify(payload) : "No payload";
  const responsePayload = hasResponse ? JSON.stringify(hasResponse) : "No response data";

  captureException(errorMessage instanceof Error ? errorMessage : new Error(errorMessage), {
    level: level || "error",
    extra: {
      message,
      payload: errorPayload,
      response: responsePayload,
    },
  });
};
