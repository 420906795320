import {
  createContext,
  useState,
  useContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";

import { auth, db } from "firebase";
import { FirebaseError } from "firebase/app";

import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  getAdditionalUserInfo,
  User,
  setPersistence,
  browserLocalPersistence,
  updatePassword,
} from "firebase/auth";
import { axiosInstance } from "@api/index";
import { doc, setDoc } from "firebase/firestore";
import { captureException, captureMessage, setUser as setSentryUser } from "@sentry/react";

export type ILogin = {
  email: string;
  password: string;
};

export type IReset = {
  email: string;
};

export type IChangePassword = {
  newPassword: string;
};

const AUTH_URL = import.meta.env.VITE_AUTH_URL;

interface AuthUser extends User {
  accessToken: string;
}

interface AuthContextType {
  user: AuthUser | null;
  loginWithGoogle: () => Promise<void>;
  loginWithPassword: (data: ILogin) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: ({ email }: IReset) => Promise<void>;
  changePassword: ({ newPassword }: IChangePassword) => Promise<void>;
  loginWithToken: () => Promise<any>;
  updateNeedsPasswordUpdate: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const googleProvider = new GoogleAuthProvider();

interface Props {
  children?: ReactNode;
}

export const AuthProvider: FC<Props> = ({ children }) => {
  const [user, _setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const setUser = useCallback((user: User | null) => {
    user && setSentryUser({ email: user.email! || user.phoneNumber!, id: user.uid });
    _setUser(user);
  }, []);

  useEffect(() => {
    setLoading(true);
    setPersistence(auth, browserLocalPersistence)
      .then(() => setUser(auth.currentUser))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  // it's important to add interceptors before browser repaints screen
  useLayoutEffect(() => {
    if (user) {
      axiosInstance.interceptors.request.use(
        (config) => {
          //@ts-ignore
          config.headers["x-jwt"] = user.accessToken;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    }
  }, [user]);

  const loginWithGoogle = useCallback(async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const info = getAdditionalUserInfo(res);
      setUser(res.user);
      if (info === null || info.isNewUser) {
        // hack to not allow new users to sign up w/google
        res.user.delete();
        logout();

        captureMessage("Non customer sign up attempt", {
          level: "warning",
          extra: { email: res.user.email },
        });
        // TODO replace alert with error in form or some toast
        alert("User not found. Become a REALLY subscriber!");
      }
    } catch (err) {
      captureException(err, {
        level: "error",
        extra: { message: "loginWithGoogle error" },
      });
      // TODO replace alert with error in form or some toast
      if (err instanceof FirebaseError) alert(err.message);
    }
  }, []);

  const loginWithPassword = useCallback(async ({ email, password }: ILogin) => {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      setUser(res.user);
    } catch (err) {
      captureException(err, {
        level: "warning",
        user: {
          email,
        },
        extra: { message: "signInWithEmailAndPassword error" },
      });
      // TODO replace alert with error in form or some toast
      if (err instanceof FirebaseError) alert(err.message);
    }
  }, []);

  const loginWithToken = useCallback(async () => {
    try {
      const loginStatus = await fetch(`${AUTH_URL}/status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // This is important
      });
      if (!loginStatus.ok) throw new Error("Unauthorized autologin attempt");

      const { customToken } = await loginStatus.json();
      const userCredential = await signInWithCustomToken(auth, customToken);
      setUser(userCredential.user);
    } catch (error) {
      captureException(error, {
        level: "error",
        extra: { message: "loginWithToken error" },
      });
      throw error;
    }
  }, []);

  const logout = useCallback(async () => {
    setUser(null);
    await fetch(`${AUTH_URL}/logout`, { method: "POST", credentials: "include" });
    await signOut(auth);
  }, []);

  const resetPassword = useCallback(async ({ email }: IReset) => {
    try {
      await sendPasswordResetEmail(auth, email);
      // TODO: use toastr
      // alert('Password reset link sent!');
    } catch (err) {
      captureException(err, {
        level: "error",
        user: {
          email,
        },
        extra: { message: "resetPassword error" },
      });
      if (err instanceof FirebaseError) alert(err);
    }
  }, []);

  const changePassword = useCallback(async ({ newPassword }: IChangePassword) => {
    if (!auth.currentUser) throw new Error("User not found");
    await updatePassword(auth.currentUser!, newPassword);
  }, []);

  const updateNeedsPasswordUpdate = useCallback(async () => {
    if (!auth.currentUser) throw new Error("User not found");

    const uid = auth.currentUser.uid;

    await setDoc(doc(db, "users", uid), { needsPasswordUpdate: false }, { merge: true });
  }, []);

  if (loading) return null;

  return (
    <AuthContext.Provider
      value={{
        // @ts-ignore
        user,
        loginWithGoogle,
        loginWithPassword,
        resetPassword,
        logout,
        loginWithToken,
        changePassword,
        updateNeedsPasswordUpdate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
