import React from "react";
import tw from "twin.macro";
import { FieldError, Merge, FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import CheckIcon from "assets/images/check.svg";

interface InputFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  register?: UseFormRegister<any>;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: boolean;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
}

const InputContainer = tw.div`w-full flex items-center mt-2.5 relative`;
const Input = tw.input`w-8 h-8 pl-4 absolute opacity-0 cursor-pointer`;
const Label = tw.label`text-lg ml-2.5`;
const IconContainer = tw.div`w-8 h-8 flex shrink-0 items-center justify-center bg-white border-2 rounded`;
const Icon = tw.img`w-4 h-4`;

export const Checkbox: React.FC<InputFieldProps> = ({
  name,
  label,
  placeholder = "",
  register,
  required = false,
  value = false,
  onChange,
  error,
}) => {
  if (!name || !label) {
    console.error("The 'name' and 'label' props are required for the Checkbox component.");
    return null;
  }

  return (
    <InputContainer>
      <Input
        id={name}
        type="checkbox"
        checked={value}
        {...(register ? register(name, { required }) : {})}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
      />
      <IconContainer className={error ? "border-red-500" : "border-black"}>
        {value && <Icon src={CheckIcon} />}
      </IconContainer>
      <Label htmlFor={name}>{label}</Label>
    </InputContainer>
  );
};
