import { ILine } from "@components/subscription/pages/Installation";
import { IDevice } from "@components/widgets/YourDevicesWidget";

export const formatPhoneNumber: (input: string) => string = (input) => {
  const digits = input.replace(/\D/g, "");
  if (digits.length < 4) return digits;
  if (digits.length < 7) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;

  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
};

export const getNumberOrdinalValue = (index: number): string => {
  const ordinals: { [key: number | string]: string } = {
    0: "first",
    1: "second",
    2: "third",
    3: "fourth",
    4: "fifth",
    5: "sixth",
    6: "seventh",
    7: "eighth",
    8: "ninth",
    9: "tenth",
  };

  return ordinals[index];
};

export function convertToDevices(lines: ILine[]): IDevice[] {
  const formattedData = lines.map((line) => {
    return {
      numbers: line.plan.name === "REALLY Clutch" ? "" : formatPhoneNumber(line.phone_number!),
      model: formatModel(line.phone_model!),
      plan: line.plan.name,
      phoneQr: line.qr_code,
    };
  });

  return formattedData;
}

function formatModel(phoneModel: string) {
  return phoneModel
    .split("_")
    .map((word) => {
      if (word === "iphone") {
        return "iPhone";
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
