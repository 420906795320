import React, { useCallback } from "react";
import tw from "twin.macro";
import QRCode from "react-qr-code";
import { Button } from "@components/subscription/Button";
import { Checkbox } from "@components/subscription/Checkbox";
import Pin from "assets/images/pin.png";
import Frame1 from "assets/images/frame1.png";
import Frame2 from "assets/images/frame2.png";
import ClutchFrame1 from "assets/images/frame-1-clutch.png";
import ClutchFrame2 from "assets/images/frame-2-clutch.png";
import ClutchFrame3 from "assets/images/frame-3-clutch.png";
import { CLUTCH_PLAN_CODE, ILine } from "../Installation";

interface SimInstallationProps {
  onNextStep: () => void;
  currentLine: ILine;
  simInstalled: boolean;
  setSimInstalled: (val: boolean) => void;
}

const Title = tw.div`text-[60px] font-light leading-none mb-[8px]`;
const Note = tw.div`text-[14px] font-light leading-none mt-[16px] `;
const SubTitle = tw.div`text-[28px] font-[400] mb-[20px] mt-[16px] leading-none`;
const FlexContainer = tw.div`flex flex-col lg:flex-row justify-center items-start lg:gap-[72px] gap-[20px]`;
const FlexItem = tw.div`flex w-full flex-col`;
const GrayCard = tw.div`w-full bg-[rgba(217, 217, 217, 0.20)] p-[32px] font-[400]`;
const CardTitle = tw.div`text-[24px] mb-[20px]`;
const CardText = tw.div`text-[16px] font-[400]`;
const Image = tw.img`flex`;
const QRContainer = tw.div`w-[240px] mb-[20px] mt-[20px]`;
const CardCircle = tw.div`rounded-full w-[56px] h-[56px] bg-[rgba(217, 217, 217, 0.70)] mb-[10px]`;
const CardImgContainer = tw.div`flex mb-[20px] mt-[20px] flex-row justify-center`;
const ButtonContainer = tw.div`w-[240px]`;
const BulletList = tw.div``;
const ListItem = tw.div`text-[28px] font-[400] mt-[10px] mb-[20px] leading-none pl-[20px]`;
const ImgContainer = tw.div``;

export const SimQR: React.FC<SimInstallationProps> = ({
  onNextStep,
  currentLine,
  simInstalled,
  setSimInstalled,
}) => {
  const isClutch = currentLine?.plan?.code === CLUTCH_PLAN_CODE;

  const onSaveAndContinue = useCallback(async () => {
    onNextStep();
  }, []);

  return (
    <FlexContainer>
      <FlexItem>
        <Title>now let's install your eSIM</Title>
        {!isClutch && (
          <Note>If you have a physical SIM, remove it before scanning this QR code</Note>
        )}
        <SubTitle>
          {isClutch
            ? "if you're on your phone, you'll need to display this on a separate device"
            : `to get you switched over, scan this QR code with your phone’s camera and follow the
          on-screen instructions. you’ll need to scan from a 2nd device.`}
        </SubTitle>
        {isClutch && (
          <BulletList>
            <ListItem>1. point your phone camera at the QR code on the other device</ListItem>
            <ListItem>2. scan and activate</ListItem>
          </BulletList>
        )}
        <QRContainer>
          <QRCode
            value={currentLine?.qr_code || "https://really.com/"}
            style={{ height: "auto", maxWidth: "100%", width: "240px" }}
          />
        </QRContainer>
        <Checkbox
          label="i've completed the eSIM install process on my device"
          name="esim_installed"
          value={simInstalled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.checked;
            setSimInstalled(newValue);
          }}
        />
        <ButtonContainer>
          <Button
            title="save & continue"
            type={simInstalled ? "active" : "disabled"}
            isSubmit
            className="lg:w-full mt-[36px]"
            onClick={onSaveAndContinue}
            disabled={!simInstalled}
          />
        </ButtonContainer>
      </FlexItem>
      <FlexItem>
        <GrayCard>
          <CardCircle>
            <Image src={Pin} />
          </CardCircle>
          <CardTitle>things to know</CardTitle>
          <CardText>
            {isClutch
              ? `1. the REALLY network will be the second SIM on the list. we recommend labeling it
            ‘REALLY Clutch’ so it’s easy to identify later.`
              : `1. if you already have a cellular plan, the REALLY network will be the second eSIM on the list. we recommend labeling it “REALLY” so it’s easy to identify later on.`}
          </CardText>
          <CardImgContainer>
            <Image src={isClutch ? ClutchFrame1 : Frame1} />
          </CardImgContainer>
          {isClutch && (
            <>
              <CardText>
                2. make sure to choose your primary line as your default cellular plan and enable
                data switching. data switching allows your phone to utilize your Clutch plan when
                your data service is unavailable.
              </CardText>
              <CardImgContainer>
                <ImgContainer>
                  <Image src={ClutchFrame2} />
                </ImgContainer>
                <ImgContainer>
                  <Image src={ClutchFrame3} />
                </ImgContainer>
              </CardImgContainer>
              <CardText>
                {`*setup steps provided are specifically for iPhone devices. Android setup is more straightforward. to change any configuration on an Android device, go to Settings > SIM Card Manager.`}
              </CardText>
            </>
          )}
          {!isClutch && (
            <>
              <CardText>
                2. if you already have a SIM, make sure to choose the REALLY line as your default
                cellular plan.
              </CardText>
              <CardImgContainer>
                <Image src={Frame2} />
              </CardImgContainer>
              <CardText>
                {`*setup steps provided are specifically for iPhone devices. Android setup is more straightforward. to change any configuration	on an Android device, got to Settings > SIM card Manager`}
              </CardText>
            </>
          )}
        </GrayCard>
      </FlexItem>
    </FlexContainer>
  );
};
