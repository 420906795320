import React from "react";
import tw from "twin.macro";
import VerizoneInstructions from "assets/images/verizone-productions.png";

const GrayCard = tw.div`w-full bg-[rgba(217, 217, 217, 0.20)] p-[32px] font-[400]`;
const CardTitle = tw.div`text-[24px] mb-[20px]`;
const CardText = tw.div`text-[16px] font-[400]`;
const ImageContainer = tw.div`w-full mt-[20px]`;
const Image = tw.img`w-full`;

export const Verizone: React.FC = () => {
  return (
    <GrayCard>
      <CardTitle>how to find your account number:</CardTitle>
      <CardText>
        1. My Verizon account using the My Verizon app on a mobile device. Click Edit Profile &
        Settings.
      </CardText>
      <ImageContainer>
        <Image src={VerizoneInstructions} />
      </ImageContainer>
    </GrayCard>
  );
};
