import tw, { styled } from "twin.macro";
import device from "assets/images/device.png";
import { ArrowRightIcon, ArrowLeftIcon } from "@assets/icons";
import { FC, useState } from "react";
import { IDevice } from ".";
import { QRCodeIcon } from "@assets/icons/QRCodeIcon";
import useComponentWidth from "@hooks/useComponentWidth";

const Image = tw.img`h-[147px] object-cover`;

const Container = tw.div`flex flex-col w-full justify-center`;
const Pagination = tw.div`flex gap-4 justify-center items-center`;
const Device = tw.div`transition-all flex gap-3 items-center shrink-0 w-full`;
const Info = tw.div`flex flex-col gap-3`;

const PhoneNumContainer = tw.div`flex items-center`;
const PhoneNumber = tw.p`leading-8 font-normal text-primary mr-4`;
const Text = tw.p`text-16 font-normal text-primary`;
const DeviceCount = tw.p`text-primary text-[13px] font-normal`;
const IconButton = tw.div`cursor-pointer text-grey rounded-full p-2 hover:bg-[#353534]`;
const QrIconButton = tw(IconButton)`-ml-2`;

type Props = {
  items: IDevice[];
  onQrClick: (value: string) => void;
};

type SliderProps = { $active: number };
const Slider = styled.div<SliderProps>(({ $active }) => [
  `transform: translateX(-${$active * 100}%);
    transition-property: transform; 
    transition-duration: 600ms;
    display: flex;`,
]);

const DeviceInfo: FC<Props> = ({ items, onQrClick }) => {
  const [ref, width] = useComponentWidth();
  const [active, setActive] = useState(0);
  const total = items.length;

  return (
    <Container ref={ref}>
      <div tw="w-full overflow-hidden">
        <Slider $active={active}>
          {items.map((item, index) => (
            <Device key={index}>
              <Image src={item.imageSrc || device} alt="device image" />
              <Info>
                <PhoneNumContainer>
                  {item.numbers && (
                    <PhoneNumber style={{ fontSize: width > 352 ? 32 : 24 }}>
                      {item.numbers}
                    </PhoneNumber>
                  )}
                  {item.phoneQr && (
                    <QrIconButton onClick={() => onQrClick(item.phoneQr)}>
                      <QRCodeIcon color="white" size={20} />
                    </QrIconButton>
                  )}
                </PhoneNumContainer>
                <Text>{item.model}</Text>
                <Text tw="mt-[-8px]">{item.plan}</Text>
              </Info>
            </Device>
          ))}
        </Slider>
      </div>
      {total > 1 ? (
        <Pagination>
          <IconButton onClick={() => setActive((active: number) => (active - 1 + total) % total)}>
            <ArrowLeftIcon size={20} />
          </IconButton>
          <DeviceCount>
            Device {active + 1} of {total}
          </DeviceCount>
          <IconButton onClick={() => setActive((active: number) => (active + 1) % total)}>
            <ArrowRightIcon size={20} />
          </IconButton>
        </Pagination>
      ) : (
        <div tw="h-[36px]" />
      )}
    </Container>
  );
};

export default DeviceInfo;
