import React from "react";

const OverlaySpinner: React.FC = () => {
  return (
    <div
      className={`absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-10`}
    >
      <div className={`text-2xl font-bold border-blue-500`}>Loading...</div>
    </div>
  );
};

export default OverlaySpinner;
