import { useNavigate } from "react-router-dom";
import { NavRoutes } from "constants/routes";
import { ResetPassword } from "@components/ResetPassword/ResetPassword";
import useEmailActions from "@hooks/useEmailActions";
import { Spinner } from "@components/common/Spinner";

const EmailActionsPage = () => {
  const navigate = useNavigate();
  const { message, isCodeValid, handleResetPassword, resetPasswordEmail, actionMode } =
    useEmailActions();

  const onGoBack = () => navigate(`/${NavRoutes.Login}`);

  if (actionMode === "resetPassword") {
    return (
      <ResetPassword
        message={message}
        isCodeValid={isCodeValid}
        onSubmit={handleResetPassword}
        footerOptions={{ onGoBack }}
        email={resetPasswordEmail}
      />
    );
  }

  return <Spinner color="white" />;
};

export default EmailActionsPage;
