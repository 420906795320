import React from "react";

type Props = {
  size?: number;
  color?: string;
};

export const SendIcon: React.FC<Props> = ({ size = 21, color = "currentColor" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
    >
      <g opacity="0.3">
        <path
          d="M9.08326 11.25L17.8333 2.50002M9.18958 11.5234L11.3797 17.1551C11.5726 17.6512 11.6691 17.8993 11.8081 17.9717C11.9286 18.0345 12.0721 18.0345 12.1927 17.9719C12.3318 17.8997 12.4285 17.6517 12.6221 17.1558L18.114 3.08269C18.2887 2.63504 18.376 2.41121 18.3283 2.26819C18.2868 2.14398 18.1893 2.04651 18.0651 2.00501C17.9221 1.95723 17.6982 2.04458 17.2506 2.21927L3.17747 7.71122C2.68158 7.90474 2.43363 8.0015 2.36137 8.14059C2.29873 8.26116 2.29882 8.4047 2.3616 8.5252C2.43402 8.66421 2.68208 8.76067 3.1782 8.95361L8.80987 11.1437C8.91058 11.1829 8.96093 11.2024 9.00333 11.2327C9.04092 11.2595 9.07378 11.2924 9.10059 11.3299C9.13083 11.3723 9.15041 11.4227 9.18958 11.5234Z"
          stroke={color}
          strokeWidth={1.67}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
