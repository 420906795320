import React, { useCallback } from "react";
import tw, { styled } from "twin.macro";
import { useLocation } from "react-router-dom";
import { defaultSteps } from "@constants/subscription";

interface Step {
  label: string;
  active: boolean;
}

interface StepperProps {
  data?: Step[];
  isComplete?: boolean;
}

interface ActiveStep {
  $active: boolean;
}

const Container = tw.div`px-[5%] py-8`;
const InnerContainer = tw.div`w-full flex justify-between gap-[20px] max-w-1200 mx-auto`;
const StepContainer = tw.div`flex grow flex-col basis-0`;

const StepLabel = styled.div<ActiveStep>`
  ${tw`flex grow lowercase lg:text-[16px] text-[13px] leading-none`}
  ${({ $active }) => ($active ? tw`text-purple` : tw`text-grey`)}
`;

const ProgressBar = styled.div<ActiveStep>`
  ${tw`flex grow h-[4px] mb-[5px] max-h-[4px]`}
  ${({ $active }) => ($active ? tw`bg-purple` : tw`bg-grey-300`)}
`;

export const Stepper: React.FC<StepperProps> = ({
  data = Object.values(defaultSteps),
  isComplete,
}: StepperProps) => {
  const location = useLocation();

  const renderSteps = useCallback(
    (data: Step[]) => {
      return data.map((item, index) => (
        <StepContainer key={index}>
          <ProgressBar $active={isComplete || item.active} />
          <StepLabel $active={isComplete || item.active}>{item.label}</StepLabel>
        </StepContainer>
      ));
    },
    [location.pathname, isComplete],
  );

  return (
    <Container>
      <InnerContainer>{renderSteps(data)}</InnerContainer>
    </Container>
  );
};
