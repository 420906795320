import React from "react";
import tw from "twin.macro";
import TMOInstructions from "assets/images/tmo-instructions.png";

const GrayCard = tw.div`w-full bg-[rgba(217, 217, 217, 0.20)] p-[32px] font-[400]`;
const CardTitle = tw.div`text-[24px] mb-[20px]`;
const CardText = tw.div`text-[16px] font-[400]`;
const ImageContainer = tw.div`w-full mt-[20px]`;
const Image = tw.img`w-full`;

export const TMO: React.FC = () => {
  return (
    <GrayCard>
      <CardTitle>how to find your account number:</CardTitle>
      <CardText>• T-Mobile app: Go to the Account tab</CardText>
      <CardText>
        • Online account: Log in to T-Mobile.com, select My Account or your name in the top right
        corner, then select Profile and Line Settings
      </CardText>
      <CardText>•Paper or digital bill: Look in the top right corner</CardText>
      <ImageContainer>
        <Image src={TMOInstructions} />
      </ImageContainer>
    </GrayCard>
  );
};
