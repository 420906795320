import React, { type ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "@components/common/Spinner";

export type ButtonType =
  | "default"
  | "outline"
  | "filled"
  | "option"
  | "disabled"
  | "text"
  | "purple"
  | "plain"
  | "active";

interface ButtonProps {
  to?: string;
  title: string;
  className?: string;
  type?: ButtonType;
  onClick?: (e: any) => void;
  isSubmit?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

interface WrapperProps extends Omit<ButtonProps, "title"> {
  children: ReactNode;
  className: string;
}

const baseStyles =
  "border border-black rounded text-[21px] leading-5 px-4 py-2 no-underline inline-block cursor-pointer flex items-center gap-2";
const optionsBaseStyles =
  "w-full h-[70px] lg:w-[300px] text-white font-normal flex justify-center items-center rounded-[4px]";

const buttonTypes = {
  default:
    "flex justify-center items-center w-[280px] h-[65px] bg-white text-black font-bold shadow-really",
  outline:
    "bg-white text-black font-normal border w-[280px] h-[65px] flex justify-center items-center border-black",
  filled: `bg-[#00000080] bg-right-bottom transition-bg-pos duration-200 ease-in-out hover:bg-left-bottom bg-buttonBgGradient bg-200p-100p ${optionsBaseStyles}`,
  option: `bg-black ${optionsBaseStyles} text-[17px] font-normal`,
  disabled: `bg-[#acaaa8] ${optionsBaseStyles} border-0 !cursor-auto`,
  text: "text-black text-[24px] font-normal border-0 underline mx-auto",
  purple: `${optionsBaseStyles} bg-[#7151f0] text-[24px] font-normal border-0 underline rounded-[35px]`,
  plain: "",
  active: `bg-[#acaaa8] bg-buttonBgGradient bg-200p-100p bg-no-repeat animate-slide-bg ${optionsBaseStyles}`,
};

const Wrapper: React.FC<WrapperProps> = ({ children, ...props }) => {
  const { isSubmit, loading, onClick, to, ...restProps } = props;

  if (onClick || isSubmit) {
    return (
      <button {...restProps} onClick={onClick} type={isSubmit ? "submit" : undefined}>
        {children}
        {loading && <Spinner color="#ffffff" />}
      </button>
    );
  } else {
    return (
      <Link to={to || ""} {...restProps}>
        {children}
      </Link>
    );
  }
};

export const Button: React.FC<ButtonProps> = ({
  to = "",
  title,
  className = "",
  type: initialType = "default",
  onClick,
  isSubmit,
  disabled,
  loading,
  ...restProps
}) => {
  const buttonStyles = useMemo(() => {
    const type = disabled || loading ? "disabled" : initialType;
    return `${baseStyles} ${buttonTypes[type]} ${className}`;
  }, [initialType, className, disabled, loading]);
  const wrapperProps = useMemo(
    () => ({ to, onClick, isSubmit, disabled, loading, ...restProps }),
    [to, onClick, isSubmit, disabled, loading, restProps],
  );

  return (
    <Wrapper {...wrapperProps} className={buttonStyles}>
      {title}
    </Wrapper>
  );
};
