import { DollarIcon, QuestionCircle } from "@assets/icons";
import { type FC } from "react";
import tw from "twin.macro";
import Widget from "./Widget";
import { formatDateToWord } from "@utils/date";
import { getStripePaymentHistory, getStripeUpdatePayment } from "@api/index";
import OverlaySpinner from "@components/OverlaySpinner";
import { Tooltip } from "react-tooltip";
import { toolTipStyles } from "./YourDevicesWidget";

const Body = tw.div`flex flex-col w-full  gap-[5px] my-[13px] mb-[-5px]`;
const Date = tw.div`text-6xl font-light lowercase`;
const Label = tw.p`text-primary text-[20px] leading-7 font-normal`;
const Desc = tw.div`text-primary text-[16px] leading-6 font-normal`;
const Autopay = tw.div`flex items-center gap-2`;
const Link = tw.div`inline text-primary cursor-pointer font-normal underline`;
const Button = tw.div`cursor-pointer flex py-2.5 px-3.5 items-center justify-center gap-1`;
const ButtonTxt = tw.p`text-primary text-[16px] leading-5 lowercase font-medium`;
const ManagePlanButton = tw(Button)`border-[1px] [border-color: #333741] rounded-lg`;
const ActionsContainer = tw.div`flex justify-end`;

interface Props {
  paymentDate: string;
  loading?: boolean;
}

const UpcomingPaymentWidget: FC<Props> = ({ paymentDate, loading }) => {
  const onPaymentHistoryClick = () => {
    const windowReference = window.open();
    getStripePaymentHistory().then((data) => {
      if (windowReference && typeof data.url === "string") windowReference.location = data.url;
    });
  };

  const onUpdatePaymentClick = () => {
    const windowReference = window.open();
    getStripeUpdatePayment().then((data) => {
      if (windowReference && typeof data.url === "string") windowReference.location = data.url;
    });
  };

  const paymentBody = (
    <Body>
      {loading && <OverlaySpinner />}
      <Label>next autopay scheduled</Label>
      <Date>{loading ? "loading" : formatDateToWord(paymentDate)}</Date>
      <Autopay>
        <Desc tw="text-fg-success-primary">autopay is enabled. </Desc>
        <QuestionCircle id="disable-autopay" size={20} />
        <Tooltip
          place="top"
          anchorSelect="#disable-autopay"
          positionStrategy="absolute"
          style={toolTipStyles}
          clickable
        >
          disabling autopay is unavailable
        </Tooltip>
      </Autopay>
      <Desc>
        billing management coming soon. for now you can{" "}
        <Link onClick={onPaymentHistoryClick}> manage your billing info via Stripe.</Link>
      </Desc>
    </Body>
  );

  return (
    <Widget
      label="upcoming payment"
      supportText="details about your next bill"
      body={paymentBody}
      footer={
        <ActionsContainer>
          <ManagePlanButton onClick={onUpdatePaymentClick}>
            <DollarIcon size={20} /> <ButtonTxt>manage payment</ButtonTxt>
          </ManagePlanButton>
        </ActionsContainer>
      }
    />
  );
};

export default UpcomingPaymentWidget;
