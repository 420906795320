import React from "react";

type Props = {
  size?: number;
  color?: string;
};

export const EyeOffIcon: React.FC<Props> = ({ size = 24, color = "currentColor" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24.00 24.00"
      version="1.1"
      fill={color}
      stroke={color}
    >
      <g id="Output-temp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="temp" transform="translate(-991.000000, -105.000000)" fill={color}>
          <path
            d="M1003,112 C1005.76,112 1008,114.24 1008,117 C1008,117.65 1007.87,118.26 1007.64,118.83 L1010.56,121.75 C1012.07,120.49 1013.26,118.86 1013.99,117 C1012.26,112.61 1007.99,109.5 1002.99,109.5 C1001.59,109.5 1000.25,109.75 999.01,110.2 L1001.17,112.36 C1001.74,112.13 1002.35,112 1003,112 L1003,112 Z M993,109.27 L995.28,111.55 L995.74,112.01 C994.08,113.3 992.78,115.02 992,117 C993.73,121.39 998,124.5 1003,124.5 C1004.55,124.5 1006.03,124.2 1007.38,123.66 L1007.8,124.08 L1010.73,127 L1012,125.73 L994.27,108 L993,109.27 L993,109.27 Z M998.53,114.8 L1000.08,116.35 C1000.03,116.56 1000,116.78 1000,117 C1000,118.66 1001.34,120 1003,120 C1003.22,120 1003.44,119.97 1003.65,119.92 L1005.2,121.47 C1004.53,121.8 1003.79,122 1003,122 C1000.24,122 998,119.76 998,117 C998,116.21 998.2,115.47 998.53,114.8 L998.53,114.8 Z M1002.84,114.02 L1005.99,117.17 L1006.01,117.01 C1006.01,115.35 1004.67,114.01 1003.01,114.01 L1002.84,114.02 L1002.84,114.02 Z"
            id="path"
          ></path>
        </g>
      </g>
    </svg>
  );
};
