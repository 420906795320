import React, { useCallback, useEffect } from "react";
import tw from "twin.macro";
import { Button } from "@components/subscription/Button";
import { ClockIcon } from "@assets/icons/ClockIcon";
import { CheckIcon } from "@assets/icons/CheckIcon";
import { ExclamationIcon } from "@assets/icons/ExclamationIcon";
import { Spinner } from "@components/common/Spinner";
import { hasError } from "../pages/PortNumber";

interface TransferingPhoneProps {
  checkStatus: (enrollment_id: string) => Promise<string | undefined>;
  enrollmentId: string;
  onSuccess: () => void;
  onFailed: () => void;
  status: string;
  portInStatus: string;
  retryPortInStatus: string;
  loading: boolean;
  intervalRef: any;
}

const Container = tw.div``;
const ModalTitle = tw.h1`text-black text-[32px] font-light leading-none break-words mb-5 mt-[60px]`;
const Text = tw.p`text-black`;
const IconContainer = tw.div`absolute top-[-62px]`;
const SpinnerContainer = tw.div`flex justify-center items-center my-[60px]`;

const CHECK_STATUS_INTERVAL = 10000;

export const TransferStatus: React.FC<TransferingPhoneProps> = ({
  checkStatus,
  enrollmentId,
  onSuccess,
  onFailed,
  status,
  portInStatus,
  retryPortInStatus,
  loading,
  intervalRef,
}) => {
  const errorStatus = [status, retryPortInStatus, portInStatus].some(hasError);

  const handleClearInterval = useCallback(() => {
    if (intervalRef.current !== undefined) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  }, [intervalRef.current]);

  useEffect(() => {
    if ((status === "InProgress" || status === "Unsubmit") && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        checkStatus(enrollmentId);
      }, CHECK_STATUS_INTERVAL) as unknown as number;

      return;
    }

    if (errorStatus || status === "Success") {
      handleClearInterval();
    }

    return () => handleClearInterval();
  }, [status, errorStatus, enrollmentId]);

  if (status === "Success") {
    return (
      <Container>
        <IconContainer>
          <CheckIcon />
        </IconContainer>
        <ModalTitle>transfer successful</ModalTitle>
        <Text>
          your number has been transferred and you should start receiving calls and texts using the
          REALLY network once your eSIM is installed.
        </Text>
        <Button
          title="activate your eSIM"
          type="active"
          isSubmit
          className="lg:w-full mt-[36px]"
          onClick={onSuccess}
          loading={loading}
        />
      </Container>
    );
  }

  if (errorStatus && !loading) {
    return (
      <Container>
        <IconContainer>
          <ExclamationIcon />
        </IconContainer>
        <ModalTitle>unfortunately, your transfer failed</ModalTitle>
        <Text>please reach out and we can help you. send an email to support@really.com</Text>
        <Button
          title="try again"
          type="active"
          isSubmit
          className="lg:w-full mt-[36px]"
          onClick={onFailed}
          loading={loading}
        />
      </Container>
    );
  }

  return (
    <Container>
      <IconContainer>
        <ClockIcon />
      </IconContainer>
      <ModalTitle>we're transfering your number</ModalTitle>
      <Text>
        this process is usually pretty quick but can take up to 1 minute, please stay on this page
        until complete
      </Text>
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    </Container>
  );
};
