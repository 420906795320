import React from "react";

type Props = {
  size?: number;
  color?: string;
};

export const ArrowRightIcon: React.FC<Props> = ({ size = 24, color = "currentColor" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M4.66602 10.6776H16.3327M16.3327 10.6776L10.4993 4.84424M16.3327 10.6776L10.4993 16.5109"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
