import { ToolsIcon } from "@assets/icons";
import { FC, useState } from "react";
import tw from "twin.macro";
import DeviceInfo from "./DeviceInfo";
import Widget from "../Widget";
import OverlaySpinner from "@components/OverlaySpinner";
import { Tooltip } from "react-tooltip";
import { QrCodeModal } from "./QrCodeModal";

const ButtonTxt = tw.p`text-primary text-[16px] leading-5 lowercase font-medium`;
const Button = tw.div`cursor-pointer flex py-2.5 px-3.5 items-center justify-center gap-1`;
const ManagePlanButton = tw(Button)`border-[1px] [border-color: #333741] rounded-lg`;
const ActionsContainer = tw.div`flex justify-between`;
const EmailLink = tw.a`underline`;

export const toolTipStyles = {
  backgroundColor: "#000000",
  borderRadius: "8px",
  color: "#FFFFFF",
  zIndex: 1,
};

export interface IDevice {
  imageSrc?: string;
  numbers: string;
  model: string;
  plan: string;
  phoneQr: string;
}

type Props = {
  items: IDevice[];
  addDeviceClick?(): void;
  managePlanClick?(): void;
  loading?: boolean;
};

const loadingData = [
  {
    numbers: "loading",
    model: "loading",
    plan: "loading",
    phoneQr: "loading",
  },
];

const YourDevicesWidget: FC<Props> = ({ items, loading }) => {
  const [phoneQr, setPhoneQr] = useState<string>("");

  const supportText = `${items.length} ${
    items.length > 1 ? "devices" : "device"
  } tied to your specific account`;

  return (
    <Widget
      label="your devices"
      supportText={supportText}
      body={<DeviceInfo items={loading ? loadingData : items} onQrClick={setPhoneQr} />}
      footer={
        <ActionsContainer>
          {phoneQr && <QrCodeModal qr={phoneQr} onCloseModal={() => setPhoneQr("")} />}
          {loading && <OverlaySpinner />}
          <Button tw="hover:bg-[#353534] rounded-lg" id="add-device">
            <ButtonTxt tw="text-tertiary">+ Add device</ButtonTxt>
          </Button>
          <Tooltip
            place="top"
            anchorSelect="#add-device"
            positionStrategy="absolute"
            style={toolTipStyles}
            clickable
          >
            contact us to add another device{" "}
            <EmailLink href="mailto:support@really.com">support@really.com</EmailLink>
          </Tooltip>
          <ManagePlanButton id="manage-plan">
            <ToolsIcon size={20} /> <ButtonTxt>manage plan</ButtonTxt>
          </ManagePlanButton>
          <Tooltip
            place="top"
            anchorSelect="#manage-plan"
            positionStrategy="absolute"
            style={toolTipStyles}
            clickable
          >
            contact us to manage plan{" "}
            <EmailLink href="mailto:support@really.com">support@really.com</EmailLink>
          </Tooltip>
        </ActionsContainer>
      }
    />
  );
};

export default YourDevicesWidget;
