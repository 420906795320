import { FC } from "react";
import tw from "twin.macro";

const Container = tw.div`relative flex-col rounded-xl bg-dark-gray h-fit`;
const WidgetHeader = tw.div`flex flex-col py-5 px-6 gap-1`;
const WidgetBody = tw.div`flex p-6 border-y-[1px] border-[#2B2B2B]`;
const WidgetFooter = tw.div`py-4 px-6`;
const WidgetName = tw.p`text-2xl leading-7 font-normal text-primary`;
const WidgetSupportText = tw.p`text-tertiary text-sm leading-5 text-ellipsis overflow-hidden lowercase`;

type Props = {
  label: string;
  supportText: string;
  body: JSX.Element;
  footer: JSX.Element;
};

const Widget: FC<Props> = ({ label, supportText, body, footer }) => {
  return (
    <Container>
      <WidgetHeader>
        <WidgetName>{label}</WidgetName>
        <WidgetSupportText>{supportText}</WidgetSupportText>
      </WidgetHeader>
      <WidgetBody>{body}</WidgetBody>
      <WidgetFooter>{footer}</WidgetFooter>
    </Container>
  );
};

export default Widget;
