import React from "react";
import Select from "react-select";
import { type FieldError, type Merge, type FieldErrorsImpl } from "react-hook-form";
import ChevronDown from "@assets/icons/chevron-down.svg";

interface DropdownProps {
  options: any;
  placeholder?: string;
  value: DropdownValue;
  onChange?: any;
  type?: DropdownType;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
}

export interface DropdownValue {
  value: string;
  label: string;
}

type StyleFn = (baseStyles: any) => any;

type StateFn = (baseStyles: any, state: { isSelected: boolean }) => any;

interface StylesConfig {
  indicatorSeparator: StyleFn;
  indicatorsContainer: StyleFn;
  control: StyleFn;
  placeholder: StyleFn;
  container: StyleFn;
  valueContainer: StyleFn;
  menu: StyleFn;
  singleValue: StyleFn;
  option: StateFn;
}

type DropdownType = "really" | "plain";

interface StylesMap {
  really: (error: any) => StylesConfig;
  plain: (error: any) => StylesConfig;
}

const CustomDropdownIndicator: React.FC = () => (
  <div>
    <img className="w-[22px] h-[22px]" src={ChevronDown} />
  </div>
);

const getStyles = (type: DropdownType, error: any, value: string): StylesConfig => {
  const styles: StylesMap = {
    really: () => ({
      indicatorSeparator: () => ({
        display: "none",
      }),
      indicatorsContainer: (baseStyles: any) => ({
        ...baseStyles,
        height: "22px",
        margin: 0,
        padding: "0px !important",
      }),
      control: (baseStyles: any) => ({
        ...baseStyles,
        padding: "20px 25px 20px 25px",
        border: "1.5px solid #000 !important",
        borderRadius: "4px",
        outline: 0,
        borderColor: "#000",
      }),
      placeholder: (baseStyles: any) => ({
        ...baseStyles,
        fontWeight: 300,
        fontSize: "22px",
        lineHeight: "22px",
      }),
      container: (baseStyles: any) => ({
        ...baseStyles,
        outline: 0,
        width: "100%",
      }),
      valueContainer: (baseStyles: any) => ({
        ...baseStyles,
        display: "flex",
        padding: 0,
        lineHeight: "normal",
      }),
      menu: (baseStyles: any) => {
        return {
          ...baseStyles,
          backgroundColor: "white",
          marginTop: "-3px",
          border: "1.5px solid #000",
          borderTop: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          outline: 0,
          lineHeight: "20px",
          fontSize: "22px",
          fontWeight: 300,
        };
      },
      singleValue: (baseStyles: any) => ({
        ...baseStyles,
        fontSize: "22px",
        color: value === "" ? "gray" : "black",
        fontWeight: 300,
      }),
      option: (baseStyles: any) => ({
        ...baseStyles,
        color: "black",
        paddingRight: "25px",
        paddingLeft: "25px",
        fontWeight: 300,
      }),
    }),
    plain: (error: any) => ({
      indicatorSeparator: () => ({
        display: "none",
      }),
      indicatorsContainer: (baseStyles: any) => ({
        ...baseStyles,
        height: "22px",
        margin: 0,
        padding: "0px !important",
      }),
      control: (baseStyles: any) => ({
        ...baseStyles,
        padding: "20px 15px 20px 15px",
        border: `1.5px solid ${error ? "#f56565" : "#000"} !important`,
        borderRadius: "4px",
        outline: 0,
      }),
      placeholder: (baseStyles: any) => ({
        ...baseStyles,
        fontSize: "24px",
        lineHeight: "18px",
      }),
      container: (baseStyles: any) => ({
        ...baseStyles,
        outline: 0,
        width: "100%",
      }),
      valueContainer: (baseStyles: any) => ({
        ...baseStyles,
        display: "flex",
        padding: 0,
        lineHeight: "normal",
      }),
      menu: (baseStyles: any) => {
        return {
          ...baseStyles,
          backgroundColor: "white",
          marginTop: "-3px",
          border: `1.5px solid ${error ? "#f56565" : "#000"}`,
          borderTop: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          outline: 0,
          lineHeight: "18px",
          fontSize: "24px",
          fontWeight: 300,
          color: "black",
        };
      },
      singleValue: (baseStyles: any) => ({
        ...baseStyles,
        fontSize: "24px",
        fontWeight: 300,
        color: value === "" ? "gray" : "black",
      }),
      option: (baseStyles: any) => ({
        ...baseStyles,
        paddingRight: "15px",
        paddingLeft: "15px",
        color: "black",
      }),
    }),
  };

  return styles[type](error);
};

export const Dropdown: React.FC<DropdownProps> = ({
  placeholder,
  options,
  value: selectedOption,
  onChange,
  type = "really",
  error,
}) => {
  return (
    <Select
      value={selectedOption}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      components={{ DropdownIndicator: CustomDropdownIndicator }}
      isSearchable={false}
      styles={getStyles(type, error, selectedOption.value)}
    />
  );
};
