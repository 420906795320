import React from "react";
import tw, { styled } from "twin.macro";
import { MenuIcon, CloseIcon, ReallyLogo } from "@assets/icons";
import SidebarNav from "@components/sidebar";
import User from "@components/User";
import { Outlet } from "react-router-dom";
import { useAuth } from "@context/authContext";
import useSidebar from "hooks/useSidebar";
import useSyncIntercom from "hooks/useSyncIntercom";
import Logout from "./Logout";

const Container = tw.div`max-w-screen-2xl mx-auto min-h-screen flex md:flex-row flex-col align-baseline bg-black`;
const MainContent = tw.div`md:h-screen overflow-auto p-3 md:p-8 md:pb-12 w-full bg-black`;
const SidebarButton = tw.button`md:hidden absolute left-0 top-0 inline-flex items-center p-2 text-sm`;
const CloseButton = tw.button`md:hidden cursor-pointer absolute right-2.5 top-2.5 p-3.5`;
const UserSection = tw.div`relative`;

// visible only on mobile
const MobileTopBar = tw.header`md:hidden  relative flex gap-2 h-8 mt-11 mb-4 mx-3 justify-center`;

const Sidebar: React.FC<{ open?: boolean; children?: React.ReactNode }> = styled.aside(
  ({ open }) => [
    tw`flex shrink-0 flex-col top-0 left-0 z-40  bg-black w-full  h-screen transition-transform 
    [position: fixed] md:w-[280px] md:translate-x-0 p-4 pb-16 md:pb-8 md:[position: initial]`,
    !open && tw`-translate-x-full`,
  ],
);

const Layout: React.FC = () => {
  const { open, toggle } = useSidebar();
  const { user } = useAuth();
  useSyncIntercom();

  return (
    <Container>
      <MobileTopBar>
        <SidebarButton onClick={toggle}>
          <MenuIcon />
        </SidebarButton>
        <ReallyLogo height={32} />
      </MobileTopBar>
      <Sidebar open={open}>
        <CloseButton onClick={toggle}>
          <CloseIcon color="white" size="42" />
        </CloseButton>
        <div className="pt-4 pl-0.5 pb-4">
          <ReallyLogo height={32} />
        </div>
        <SidebarNav />
        <UserSection>
          <User name={user!.displayName!} email={user!.email!} avatarUrl={user!.photoURL!} />
          <Logout />
        </UserSection>
      </Sidebar>
      <MainContent>
        <Outlet />
      </MainContent>
    </Container>
  );
};

export default Layout;
