import React, { useState, useContext } from "react";
import tw from "twin.macro";
import { ILine } from "@components/subscription/pages/Installation";
import { SubscriptionDataContext } from "@context/subscriptionContext";
import { SimQR } from "./SimQR";
import { SimActivation } from "./SimActivation";

interface SimInstallationProps {
  onNextStep: () => void;
  currentLine: ILine;
}

const Content = tw.div`flex justify-between flex-col gap-0 max-w-1200 mx-auto`;
const Container = tw.div``;

export const SimInstallation: React.FC<SimInstallationProps> = ({ onNextStep, currentLine }) => {
  const { data } = useContext(SubscriptionDataContext);
  const [simInstalled, setSimInstalled] = useState(false);
  const [activeSim, setSimActive] = useState(data?.activeLines?.[currentLine?.enrollment_id]);

  if (!activeSim && !currentLine?.port_number) {
    return (
      <Container>
        <Content>
          <SimActivation setActiveSim={setSimActive} currentLine={currentLine} />
        </Content>
      </Container>
    );
  }
  return (
    <Container>
      <Content>
        <SimQR
          onNextStep={onNextStep}
          currentLine={currentLine}
          simInstalled={simInstalled}
          setSimInstalled={setSimInstalled}
        />
      </Content>
    </Container>
  );
};
