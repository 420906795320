import tw from "twin.macro";

const Container = tw.div`flex flex-col gap-4`;
const Title = tw.p`text-5xl font-light leading-9 text-primary lowercase pb-8`;
const Card = tw.div`relative flex-col rounded-xl bg-dark-gray h-fit p-[24px]`;
const Subtitle = tw.p`text-[24px] font-light leading-9 text-primary lowercase pb-4`;
const Paragraph = tw.p`text-[15px] font-light leading-9 text-primary lowercase pb-4 leading-tight`;

const PrivacyAndSecurity = () => {
  return (
    <Container>
      <Title>privacy & security</Title>
      <Card>
        <Subtitle>freedom</Subtitle>
        <Paragraph>
          at really, we’re dedicated to providing the worlds first fully encrypted and private phone
          service. our goal is to protect your data, eliminate dead zones, and pioneer a
          decentralized network. we empower our members with unparalleled control and security over
          their communications, advocating for the freedom to secure private communication for
          everyone.
        </Paragraph>
        <Subtitle>privacy + security declaration</Subtitle>
        <Paragraph>
          really is attacking the current paradigm with its decentralized network by offering
          best-in-class privacy and security tools, anonymizing user data and storing almost
          nothing. with enough people using really, we will end the digital divide, unleashing the
          full potential of humanity, where freedom, democracy and conversation can thrive.
        </Paragraph>
        <Subtitle>our commitment</Subtitle>
        <Paragraph>
          really will continue to improve, optimize, and innovate in the privacy and security space.
          as we advance, our customers will gain even more value from their wireless plans, enjoying
          enhanced features and benefits that ensure their communications remain secure and private.
          over time, users can expect continuous improvements that further solidify our commitment
          to providing the most secure and private wireless service available.
        </Paragraph>
      </Card>
    </Container>
  );
};

export default PrivacyAndSecurity;
