import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SubscriptionDataContext } from "@context/subscriptionContext";
import { useScrollToTop } from "@hooks/useScrollTop";
import { SimInstallation } from "./installation/SimInstallation";
import { RestartPhone } from "./installation/RestartPhone";
import { DeleteSim } from "./installation/DeleteSim";
import { EnableData } from "./installation/EnableData";
import { EnableRoaming } from "./installation/EnableRoaming";
import { UpdateMessageApp } from "./installation/UpdateMessageApp";
import { FacetimeApp } from "./installation/FacetimeApp";
import { Voicemail } from "./installation/Voicemail";
import { Success } from "./installation/Success";

export interface Plan {
  id: number;
  code: number;
  name: string;
  description: string;
  price: number;
  duration: string;
  taxes_and_fees: boolean;
  additional_line_price: number;
  expiry_date?: string; // temp solution
  start_date?: string; // temp solution
}
export interface ILine {
  plan: Plan;
  port_number: string;
  phone_number?: string; // temp solution
  phone_model?: string; // temp solution
  zip_code: string;
  is_esim: boolean;
  active: boolean;
  enrollment_id: string;
  qr_code: string;
}

interface InstallationProps {
  onSave: (id: number, payload: any) => void;
  lineIndex: number;
  currentLine: ILine;
  totalLines?: number;
  setIsOnboardingComplete: (status: boolean) => void;
}

type STEPS = { [key: number]: string };

export const CLUTCH_PLAN_CODE = 2;

const arrayToSteps = (steps: string[]): STEPS => {
  return steps.reduce((acc, step, index) => {
    acc[index] = step;
    return acc;
  }, {} as STEPS);
};

export const Installation: React.FC<InstallationProps> = ({
  onSave,
  lineIndex,
  currentLine,
  totalLines,
  setIsOnboardingComplete,
}) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const { setGoBack } = useContext(SubscriptionDataContext);
  const isClutch = currentLine?.plan?.code === CLUTCH_PLAN_CODE;

  const INSTALLATION_STEPS: STEPS = useMemo(() => {
    const steps = [
      "sim_installation",
      "delete_old_sim",
      "restart_phone",
      "enable_data",
      "enable_roaming",
      "update_message_app",
      "facetime_config",
      "enable_voicemail",
      "success",
    ];

    if (isClutch) {
      const clutchStepsToRemove = [
        "delete_old_sim",
        "update_message_app",
        "facetime_config",
        "enable_voicemail",
      ];
      return arrayToSteps(steps.filter((step) => !clutchStepsToRemove.includes(step)));
    }

    return arrayToSteps(steps);
  }, [isClutch]);

  const numberOfSteps = useMemo(
    () => Object.values(INSTALLATION_STEPS).length - 1,
    [INSTALLATION_STEPS],
  );
  const getStepName = (currentStep: number) => INSTALLATION_STEPS[currentStep];

  const handleNextStep = useCallback(() => {
    setCurrentStep((step) => step + 1);
  }, [currentStep, setCurrentStep]);

  useEffect(() => {
    if (currentStep > numberOfSteps) {
      setGoBack(() => navigate(-1));
      setCurrentStep(0);
      return;
    }

    setGoBack(() => {
      if (currentStep - 1 < 0) {
        navigate(-1);
      }
      setCurrentStep(currentStep - 1);
    });
  }, [currentStep]);

  useScrollToTop(currentStep);

  if (getStepName(currentStep) === "delete_old_sim") {
    return <DeleteSim onNextStep={handleNextStep} />;
  }

  if (getStepName(currentStep) === "restart_phone") {
    return <RestartPhone onNextStep={handleNextStep} isClutch={isClutch} />;
  }

  if (getStepName(currentStep) === "enable_data") {
    return <EnableData onNextStep={handleNextStep} isClutch={isClutch} />;
  }

  if (getStepName(currentStep) === "enable_roaming") {
    return <EnableRoaming onNextStep={handleNextStep} isClutch={isClutch} />;
  }

  if (getStepName(currentStep) === "update_message_app") {
    return <UpdateMessageApp onNextStep={handleNextStep} />;
  }

  if (getStepName(currentStep) === "facetime_config") {
    return <FacetimeApp onNextStep={handleNextStep} />;
  }

  if (getStepName(currentStep) === "enable_voicemail") {
    return <Voicemail onNextStep={handleNextStep} />;
  }

  if (getStepName(currentStep) === "success") {
    return (
      <Success
        onSave={onSave}
        lineIndex={lineIndex}
        onNextStep={handleNextStep}
        totalLines={totalLines}
        setIsOnboardingComplete={setIsOnboardingComplete}
      />
    );
  }

  return <SimInstallation onNextStep={handleNextStep} currentLine={currentLine} />;
};
