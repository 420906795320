import React from "react";

type Props = {
  size?: number;
  color?: string;
};

export const ClockIcon: React.FC<Props> = ({ size = 48, color = "#DC6803" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
        fill="#FEF0C7"
      />
      <path
        d="M21 30.5H27M18.6 14H29.4C29.9601 14 30.2401 14 30.454 14.109C30.6422 14.2049 30.7951 14.3578 30.891 14.546C31 14.7599 31 15.0399 31 15.6V17.6745C31 18.1637 31 18.4083 30.9447 18.6385C30.8957 18.8425 30.8149 19.0376 30.7053 19.2166C30.5816 19.4184 30.4086 19.5914 30.0627 19.9373L27.1314 22.8686C26.7354 23.2646 26.5373 23.4627 26.4632 23.691C26.3979 23.8918 26.3979 24.1082 26.4632 24.309C26.5373 24.5373 26.7354 24.7354 27.1314 25.1314L30.0627 28.0627C30.4086 28.4086 30.5816 28.5816 30.7053 28.7834C30.8149 28.9624 30.8957 29.1575 30.9447 29.3615C31 29.5917 31 29.8363 31 30.3255V32.4C31 32.9601 31 33.2401 30.891 33.454C30.7951 33.6422 30.6422 33.7951 30.454 33.891C30.2401 34 29.9601 34 29.4 34H18.6C18.0399 34 17.7599 34 17.546 33.891C17.3578 33.7951 17.2049 33.6422 17.109 33.454C17 33.2401 17 32.9601 17 32.4V30.3255C17 29.8363 17 29.5917 17.0553 29.3615C17.1043 29.1575 17.1851 28.9624 17.2947 28.7834C17.4184 28.5816 17.5914 28.4086 17.9373 28.0627L20.8686 25.1314C21.2646 24.7354 21.4627 24.5373 21.5368 24.309C21.6021 24.1082 21.6021 23.8918 21.5368 23.691C21.4627 23.4627 21.2646 23.2646 20.8686 22.8686L17.9373 19.9373C17.5914 19.5914 17.4184 19.4184 17.2947 19.2166C17.1851 19.0376 17.1043 18.8425 17.0553 18.6385C17 18.4083 17 18.1637 17 17.6745V15.6C17 15.0399 17 14.7599 17.109 14.546C17.2049 14.3578 17.3578 14.2049 17.546 14.109C17.7599 14 18.0399 14 18.6 14Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
