import { FC } from "react";
import tw from "twin.macro";
import Widget from "../Widget";
import { SendIcon } from "@assets/icons";
import helpImage from "@assets/images/helpdesk.png";

const ButtonTxt = tw.p`text-primary opacity-30 leading-5 lowercase font-normal`;
const Button = tw.div`cursor-pointer flex py-2.5 px-3.5 items-center justify-between gap-1 w-full`;
const Body = tw.div`flex flex-col justify-end w-full gap-3 h-[185px]`;

const ConciergeAvatar = tw.img`w-[50px] h-[50px] rounded-full`;

const ConciergeTxtContainer = tw.div`flex p-3 items-start gap-3 self-stretch rounded-lg bg-[#151515]`;
const ConciergeText = tw.p`text-[#B4B4B4] font-normal text-16 leading-6`;
type Props = {
  sendMessageClicked(): void;
};

const ConciergeWidget: FC<Props> = ({ sendMessageClicked }) => {
  const conciergeBody = (
    <Body>
      <ConciergeAvatar src={helpImage} />
      <ConciergeTxtContainer>
        <ConciergeText>hey, i’m ronnie. how can i help?</ConciergeText>
      </ConciergeTxtContainer>
    </Body>
  );

  return (
    <Widget
      label="your VIP concierge"
      supportText="meet your personal assistant"
      body={conciergeBody}
      footer={
        <Button tw="hover:bg-[#353534] rounded-lg" onClick={sendMessageClicked}>
          <ButtonTxt tw="text-tertiary">send a message</ButtonTxt>
          <SendIcon />
        </Button>
      }
    />
  );
};

export default ConciergeWidget;
