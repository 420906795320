import React, { useContext, useState, useCallback, useRef } from "react";
import tw from "twin.macro";
import { Form } from "react-router-dom";
import { SubscriptionDataContext, SubscriptionFlowData } from "@context/subscriptionContext";
import { useForm, Controller } from "react-hook-form";
import { Dropdown, DropdownValue } from "@components/subscription/Dropdown";
import { InputField } from "@components/subscription/InputField";
import { Button } from "@components/subscription/Button";
import { Modal } from "@components/subscription/Modal";
import { usePortIn } from "@hooks/usePortIn";
import { TransferStatus } from "../modals/TransferStatus";
import { SkipPorting } from "../modals/SkipPorting";
import { ILine } from "./Installation";
import { ATT } from "./carrier-instructions/ATT";
import { TMO } from "./carrier-instructions/TMO";
import { Verizone } from "./carrier-instructions/Verizone";
import { OtherInstructions } from "./carrier-instructions/OtherInstructions";

interface PortNumberProps {
  onSave: (id: number, payload: any) => void;
  lineIndex: number;
  currentLine: ILine;
  handleSkipPorting: () => any;
}

type Instructions = { [key: string]: React.ReactNode };
type DropdownOption = { value: string; label: string };

const Content = tw.div`flex justify-between flex-col gap-0 max-w-1200 mx-auto`;
const Container = tw.div`px-[5%]`;
const Title = tw.div`text-[48px] font-light leading-none mb-[8px] font-[300]`;
const SubTitle = tw.div`text-[24px] font-[400] mb-[64px]`;
const FlexContainer = tw.div`flex flex-col lg:flex-row justify-center items-start lg:gap-[72px] gap-[40px]`;
const FlexItem = tw.div`flex w-full flex-col`;
const DropdownContainer = tw.div`w-full flex justify-center items-start flex-col`;
const DropdownLabel = tw.label`text-[16px] font-normal leading-none text-center mb-[12px]`;
const InputContainer = tw.div`mt-[32px]`;
const ButtonSeparator = tw.div`text-[24px] my-[36px] flex justify-center text-[400]`;

export const hasError = (status: string) => status.toLowerCase().includes("error");

export const PortNumber: React.FC<PortNumberProps> = ({
  onSave,
  lineIndex,
  currentLine,
  handleSkipPorting,
}) => {
  const [open, setOpen] = useState(false);
  const [skipModalOpen, setSkipModalOpen] = useState(false);

  const [formPayload, setFormPayload] = useState({});
  const { addData } = useContext(SubscriptionDataContext);
  const checkStatusInterval = useRef<number | undefined>();
  const {
    loading,
    portIn,
    checkStatus,
    status,
    portInStatus,
    retryPortInStatus,
    retryPortIn,
    clearStatus,
  } = usePortIn();

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    getValues,
  } = useForm<Partial<SubscriptionFlowData>>({
    defaultValues: {
      carrier: "",
      accountNumber: "",
      transferPin: "",
      zipcode: "",
    },
  });

  const values = getValues();
  const isButtonActive =
    values.carrier && values.accountNumber && values.transferPin && values.zipcode;

  const onSubmit = async (formData: Partial<SubscriptionFlowData>) => {
    clearStatus();
    setOpen(true);

    const currentStatus = await checkStatus(currentLine.enrollment_id);

    setFormPayload(formData);
    const payload = { ...formData, enrollment_id: currentLine.enrollment_id };

    if (hasError(currentStatus || "") || !currentStatus) {
      await retryPortIn(payload);
      await checkStatus(currentLine.enrollment_id);
      return;
    }

    if (currentStatus === "Unsubmit") {
      portIn(payload);
      return;
    }
  };

  const onCloseModal = useCallback(() => {
    setOpen(false);
    clearInterval(checkStatusInterval.current);
    checkStatusInterval.current = undefined;
  }, [setOpen]);

  const handleOnSuccess = useCallback(() => {
    addData({ skipPortStep: false });
    onSave(lineIndex, { ported_completed: true });
  }, []);

  const handleOnFailed = useCallback(async () => {
    if (hasError(portInStatus) || hasError(status)) {
      clearStatus();
      await retryPortIn({ ...formPayload, enrollment_id: currentLine.enrollment_id });
      await checkStatus(currentLine.enrollment_id);
      return;
    }

    await checkStatus(currentLine.enrollment_id);
  }, [status, formPayload, retryPortIn, portInStatus]);

  const getCarrierInstructions = useCallback(() => {
    const carrier = values.carrier as unknown as DropdownOption;

    const instructions: Instructions = {
      att: <ATT />,
      tmo: <TMO />,
      verizon: <Verizone />,
      TMBRLY: <OtherInstructions />,
    };

    if (values.carrier) {
      return instructions[carrier.value];
    }

    return <ATT />;
  }, [values.carrier]);

  return (
    <Container>
      <Content>
        <Title>almost done, let's port your number.</Title>
        <SubTitle>2 final steps to get you activated and switched over</SubTitle>
        <FlexContainer>
          <FlexItem>
            <Form method="post" onSubmit={handleSubmit(onSubmit)} className="w-full">
              <Controller
                name="carrier"
                control={control}
                rules={{
                  required: true,
                }}
                render={(form) => {
                  const { field } = form;
                  const { onChange, value } = field;

                  return (
                    <DropdownContainer>
                      <DropdownLabel
                        htmlFor={"state"}
                        className={`input-hide-arrows ${
                          errors.carrier ? "text-red-500" : "text-black"
                        }`}
                      >
                        which carrier are you switching from?
                      </DropdownLabel>
                      <Dropdown
                        value={value as unknown as DropdownValue}
                        type="plain"
                        onChange={(e: { label: string; value: string }) => {
                          onChange(e);
                          addData({ carrier: e.value });
                        }}
                        options={[
                          { value: "att", label: "AT&T" },
                          { value: "tmo", label: "T-Mobile" },
                          { value: "verizon", label: "Verizon" },
                          { value: "TMBRLY", label: "Other" },
                        ]}
                        error={errors.carrier}
                      />
                    </DropdownContainer>
                  );
                }}
              />
              <Controller
                name="accountNumber"
                control={control}
                rules={{
                  required: true,
                }}
                render={(form) => {
                  const { field } = form;
                  const { onChange, value } = field;

                  return (
                    <InputContainer>
                      <InputField
                        label="what was your account number?"
                        register={register}
                        name="accountNumber"
                        required
                        error={errors.accountNumber}
                        type="text"
                        value={value}
                        placeholder="xx-xxxxxxxx"
                        className={"!h-[64px]"}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(e.target.value);
                          addData({ accountNumber: e.target.value });
                        }}
                      />
                    </InputContainer>
                  );
                }}
              />
              {values.accountNumber && (
                <>
                  <Controller
                    name="transferPin"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={(form) => {
                      const { field } = form;
                      const { onChange, value } = field;

                      return (
                        <InputContainer>
                          <InputField
                            label="what is your number transfer PIN?"
                            register={register}
                            name="transferPin"
                            required
                            error={errors.transferPin}
                            type="text"
                            value={value}
                            className={"!h-[64px]"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              onChange(e.target.value);
                              addData({ transferPin: e.target.value });
                            }}
                          />
                        </InputContainer>
                      );
                    }}
                  />
                  <Controller
                    name="zipcode"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={(form) => {
                      const { field } = form;
                      const { onChange, value } = field;

                      return (
                        <InputContainer>
                          <InputField
                            label="what is the billing zip code on the account?"
                            register={register}
                            name="zipcode"
                            required
                            error={errors.zipcode}
                            type="text"
                            value={value}
                            className={"!h-[64px]"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              onChange(e.target.value);
                              addData({ zipcode: e.target.value });
                            }}
                          />
                        </InputContainer>
                      );
                    }}
                  />
                </>
              )}
              <Button
                title="keep going"
                type="active"
                isSubmit
                className="lg:w-full mt-[36px]"
                disabled={!isButtonActive}
              />
            </Form>
            <ButtonSeparator>OR</ButtonSeparator>
            <Button
              title="skip this for now"
              type={"outline"}
              className="w-full"
              onClick={() => setSkipModalOpen(true)}
            />
          </FlexItem>
          <FlexItem>{getCarrierInstructions()}</FlexItem>
        </FlexContainer>
      </Content>
      <Modal
        Component={
          <TransferStatus
            checkStatus={checkStatus}
            enrollmentId={currentLine?.enrollment_id}
            onSuccess={handleOnSuccess}
            onFailed={handleOnFailed}
            status={status}
            portInStatus={portInStatus}
            retryPortInStatus={retryPortInStatus}
            loading={loading}
            intervalRef={checkStatusInterval}
          />
        }
        open={open}
        onCloseModal={onCloseModal}
      />
      <Modal
        Component={<SkipPorting setOpen={setSkipModalOpen} handleSkipPorting={handleSkipPorting} />}
        open={skipModalOpen}
        onCloseModal={() => setSkipModalOpen(false)}
      />
    </Container>
  );
};
