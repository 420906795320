import React from "react";
import tw from "twin.macro";
import { Button } from "@components/subscription/Button";

interface RestartPhoneProps {
  onNextStep: () => void;
  isClutch: boolean;
}

const Content = tw.div`flex justify-between flex-col gap-0 max-w-1200 mx-auto`;
const Container = tw.div`px-[5%]`;
const Title = tw.div`text-[60px] font-light leading-none mb-[8px] font-[300]`;
const SubTitle = tw.div`text-[28px] font-[400] mb-[20px] leading-none	mt-[20px]`;
const FlexContainer = tw.div`flex flex-col lg:flex-row justify-start items-start lg:gap-[72px] gap-[0]`;
const FlexItem = tw.div`flex w-full lg:w-[80%] flex-col`;
const ButtonContainer = tw.div`w-[240px]`;

export const RestartPhone: React.FC<RestartPhoneProps> = ({ onNextStep, isClutch }) => {
  return (
    <Container>
      <Content>
        <FlexContainer>
          <FlexItem>
            <Title>
              {isClutch
                ? "okay your eSIM has been activated and is ready to use!"
                : "okay, let’s restart your phone"}
            </Title>
            {!isClutch && (
              <SubTitle>{`once restarted, we’ll install the new eSIM and finalize everything.`}</SubTitle>
            )}
            <ButtonContainer>
              <Button
                title="keep going"
                type={"active"}
                isSubmit
                className="lg:w-full mt-[36px]"
                onClick={() => onNextStep()}
              />
            </ButtonContainer>
          </FlexItem>
        </FlexContainer>
      </Content>
    </Container>
  );
};
