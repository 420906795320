import { useCallback, useEffect, useState } from "react";
import { getCustomerInfo } from "@api/index";
import { ILine } from "@components/subscription/pages/Installation";
import { IDevice } from "@components/widgets/YourDevicesWidget";
import { convertToDevices } from "@utils/formatters";
import { getFirstAvailableExpiryDate } from "@utils/date";
import { handleApiError } from "@utils/errorHandler";

export interface CustomerInfo {
  lines: ILine[];
}
interface ReturnType {
  loading: boolean;
  devices: IDevice[];
  upcomingPaymentDate: string;
  data?: CustomerInfo;
  error: any | string;
}

export const useCustomerInfo = (): ReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();
  const [data, setData] = useState<CustomerInfo>();
  const [devices, setDevices] = useState<IDevice[]>([]);
  const [upcomingPaymentDate, setUpcomingPaymentDate] = useState<string>("NA");

  const fetchInfo = useCallback(async () => {
    setLoading(true);

    try {
      const data = await getCustomerInfo();
      setDevices(convertToDevices(data.lines));
      setUpcomingPaymentDate(getFirstAvailableExpiryDate(data.lines));
      setData(data);
    } catch (err: any) {
      handleApiError({ err, message: "getCustomerInfo error" });
      setError(err);
    }

    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    fetchInfo();
  }, []);

  return {
    loading,
    upcomingPaymentDate,
    devices,
    data,
    error,
  };
};
