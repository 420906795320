import React from "react";
import tw from "twin.macro";
import { Modal } from "@components/subscription/Modal";
import QRCode from "react-qr-code";

const QRContainer = tw.div`w-[240px] lg:w-[360px]`;

const QrBody: React.FC<{ value: string }> = ({ value }) => {
  return (
    <QRContainer>
      <QRCode value={value} style={{ height: "auto", width: "100%" }} />
    </QRContainer>
  );
};
export const QrCodeModal: React.FC<{ qr: string; onCloseModal: () => void }> = ({
  qr,
  onCloseModal,
}) => {
  return <Modal Component={<QrBody value={qr} />} open={true} onCloseModal={onCloseModal} />;
};
