import { useCallback, useEffect, useState } from "react";
import { getStripePaymentHistory } from "@api/index";
import { useLocation } from "react-router-dom";
import { handleApiError } from "@utils/errorHandler";

interface ReturnType {
  loading: boolean;
  stripeConfigUrl?: string;
  unhandledError: any | string;
}

export const useStripeConfig = (): ReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const [unhandledError, setUnhandledError] = useState();
  const [stripeConfigUrl, setStripeConfigUrl] = useState("");
  const { search } = useLocation();

  useEffect(() => {
    getStripeConfig();
  }, [search]);

  const getStripeConfig = useCallback(async () => {
    setLoading(true);

    try {
      const stripeConfig = await getStripePaymentHistory();

      setStripeConfigUrl(stripeConfig.url);
    } catch (err: any) {
      handleApiError({
        err,
        level: "error",
        message: "getStripePaymentHistory error",
      });
      setUnhandledError(err);
    }

    setLoading(false);
  }, [setLoading, setStripeConfigUrl, setUnhandledError]);

  return {
    loading,
    stripeConfigUrl,
    unhandledError,
  };
};
