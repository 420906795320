import axios from "axios";
import { IOrderInfo } from "@hooks/useOrderInfo";
import { AccountInfo, PortInResponse } from "@hooks/usePortIn";
import { UpdateLinePayload, UpdateLineResponse } from "@hooks/useUpdateLine";
import { CustomerInfo } from "@hooks/useCustomerInfo";

interface StripeConfigResponse {
  url: string;
  message: string;
}

// Create an Axios instance
export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
});

export const getOrderInfoData = async (): Promise<IOrderInfo> => {
  return await axiosInstance.get("/api/v1/subscriber/order-info").then((res) => res.data);
};

export const postPortIn = async (payload: AccountInfo) => {
  return await axiosInstance
    .post<PortInResponse>("/api/v1/subscriber/port-in", payload)
    .then((res) => res.data);
};

export const getPortInStatus = async (enrollmentId: string) => {
  return await axiosInstance
    .get<PortInResponse>(`/api/v1/subscriber/port-in-status/${enrollmentId}`)
    .then((res) => res.data);
};

export const updatePortIn = async (payload: AccountInfo) => {
  return await axiosInstance
    .put<PortInResponse>("/api/v1/subscriber/port-in", payload)
    .then((res) => res.data);
};

export const updateActiveLineStatus = async (payload: UpdateLinePayload) => {
  return await axiosInstance
    .post<UpdateLineResponse>("/api/v1/subscriber/activate-line", payload)
    .then((res) => res.data);
};

export const getActivationStatus = async (enrollmentId: string) => {
  return await axiosInstance
    .get<UpdateLineResponse>(`/api/v1/subscriber/line-status/${enrollmentId}`)
    .then((res) => res.data);
};

export const getStripeUpdatePayment = async () => {
  return await axiosInstance
    .get<StripeConfigResponse>("/api/v1/subscriber/payment-login-link")
    .then((res) => res.data);
};

export const getStripePaymentHistory = async () => {
  return await axiosInstance
    .get<StripeConfigResponse>("/api/v1/subscriber/invoice-login-link")
    .then((res) => res.data);
};

export const getCustomerInfo = async () => {
  return await axiosInstance
    .get<CustomerInfo>("/api/v1/subscriber/customer-info")
    .then((res) => res.data);
};
