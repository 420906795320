import React from "react";

export const ExclamationMark: React.FC = () => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="white" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <rect x="6" y="6" width="26" height="26" rx="13" stroke="#D92D20" stroke-width="2" />
      </g>
      <g opacity="0.1">
        <rect x="1" y="1" width="36" height="36" rx="18" stroke="#D92D20" stroke-width="2" />
      </g>
      <g clip-path="url(#clip0_4843_411082)">
        <path
          d="M19 15.6667V19M19 22.3334H19.0084M27.3334 19C27.3334 23.6024 23.6024 27.3334 19 27.3334C14.3976 27.3334 10.6667 23.6024 10.6667 19C10.6667 14.3976 14.3976 10.6667 19 10.6667C23.6024 10.6667 27.3334 14.3976 27.3334 19Z"
          stroke="#D92D20"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4843_411082">
          <rect width="20" height="20" fill="white" transform="translate(9 9)" />
        </clipPath>
      </defs>
    </svg>
  );
};
