import tw from "twin.macro";
import { useIntercom } from "react-use-intercom";

const Container = tw.div`flex flex-col gap-4`;
const Title = tw.p`text-5xl font-light leading-9 text-primary lowercase pb-1`;
const TitleSection = tw.p`text-4xl font-light leading-9 text-primary lowercase pb-1`;
const Card = tw.div`relative flex-col rounded-xl bg-dark-gray h-fit p-[24px] flex-1 min-h-[224px]`;
const Subtitle = tw.p`text-[24px] font-normal leading-9 text-primary lowercase pb-1`;
const Paragraph = tw.p`text-[15px] font-light leading-9 text-primary lowercase pb-4 leading-tight`;
const CardContainer = tw.div`flex flex-col xl:flex-row gap-[20px]`;
const List = tw.ul`pl-[10px]`;
const Bullet = tw.span`px-[5px]`;
const Link = tw.a`underline`;
const InlineContainer = tw.div`mt-[20px]`;

const Support = () => {
  const { show } = useIntercom();

  return (
    <Container>
      <Title>support</Title>
      <Paragraph>
        we’re here to help you troubleshoot and resolve any issues you might be facing. if you don't
        see what you're looking for use our chat tool to speak with our support team.
      </Paragraph>
      <TitleSection>common issues</TitleSection>
      <CardContainer>
        <Card>
          <Subtitle>phone not working properly?</Subtitle>
          <Paragraph>
            we know it's cliche, but restarting your phone often solves for a glitch in connection.
            it's also best practice anytime you update settings.
          </Paragraph>
        </Card>
        <Card>
          <Subtitle>coverage concerns?</Subtitle>
          <Paragraph>
            check your settings:
            <List>
              <li>
                <Bullet>•</Bullet> go to settings -{">"} cellular
              </li>
              <li>
                <Bullet>•</Bullet> choose your REALLY SIM
              </li>
              <li>
                <Bullet>•</Bullet> ensure data roaming is on
              </li>
              <li>
                <Bullet>•</Bullet> if the issue persists, contact support
              </li>
            </List>
          </Paragraph>
        </Card>
        <Card>
          <Subtitle>payment issues?</Subtitle>
          <Paragraph>
            <List>
              <li>
                <Bullet>•</Bullet>
                you can update your payment method by going to billing & lines
              </li>
              <li>
                <Bullet>•</Bullet>for other issues, contact support
              </li>
            </List>
          </Paragraph>
        </Card>
      </CardContainer>
      <TitleSection>reach out</TitleSection>
      <Card>
        <Subtitle>need additional support?</Subtitle>
        <Paragraph>
          here at really, we pride ourselves on delivering exceptional customer support. our
          dedicated team is committed to providing reliable assistance, ensuring our customers
          receive the best possible experience.
          <InlineContainer>
            got questions? check out our{" "}
            <Link href="https://really.com/faqs" target="_blank">
              faq
            </Link>{" "}
            page, it’s loaded with information and great tips.
          </InlineContainer>
        </Paragraph>
        <Subtitle>contact us</Subtitle>
        <Paragraph>
          hit us up! we’re here for you just let us know how we can help. reach out to us by email
          at <Link href="mailto:support@really.com">support@really.com</Link>or{" "}
          <Link onClick={show}>chat with us</Link> directly.
        </Paragraph>
      </Card>
    </Container>
  );
};

export default Support;
