import { useCallback, useEffect, useState } from "react";
import { getOrderInfoData } from "@api/index";
import { useLocation } from "react-router-dom";
import { ILine } from "@components/subscription/pages/Installation";
import { handleApiError } from "@utils/errorHandler";

export interface IOrderInfo {
  lines: ILine[];
}
interface ReturnType {
  loading: boolean;
  orderInfo?: IOrderInfo;
}

export const useOrderInfo = (): ReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const [orderInfo, setOrderInfo] = useState<IOrderInfo>();
  const { search } = useLocation();

  useEffect(() => {
    getOrderInfo();
  }, [search]);

  const getOrderInfo = useCallback(async () => {
    setLoading(true);

    try {
      const orderInfoData = await getOrderInfoData();

      const inactiveLines = orderInfoData.lines.filter((line) => !line.active);

      setOrderInfo({ ...orderInfoData, lines: inactiveLines });
    } catch (err: any) {
      handleApiError({
        err,
        message: "getOrderInfoData error",
      });
    }

    setLoading(false);
  }, []);

  return {
    loading,
    orderInfo,
  };
};
