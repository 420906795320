import React from "react";

type Props = {
  size?: number;
  color?: string;
};

export const EyeIcon: React.FC<Props> = ({ size = 24, color = "currentColor" }) => {
  return (
    <svg
      fill={color}
      width={size}
      height={size}
      viewBox="-0.96 -0.96 25.92 25.92"
      id="b80c53a0-fbf6-4494-bbaf-81c896ec0e14"
      data-name="Livello 1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="11.88" r="3"></circle>
      <path d="M12,4A13,13,0,0,0,0,12a13,13,0,0,0,24,0A13,13,0,0,0,12,4Zm0,12.88a5,5,0,1,1,5-5A5,5,0,0,1,12,16.88Z"></path>{" "}
    </svg>
  );
};
