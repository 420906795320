import React from "react";
import tw from "twin.macro";
import logo from "@assets/images/really.png";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { ArrowLeftIcon } from "@assets/icons";
import { Spinner } from "@components/common/Spinner";

export interface ResetPasswordProps {
  onSubmit: (data: { newPassword: string }) => void;
  FooterComponent?: React.ComponentType;
  isCodeValid: boolean;
  message: string;
  email: string;
  footerOptions?: {
    title?: string;
    onGoBack?: () => void;
  };
}

const Container = tw.div`flex flex-col justify-center items-center h-screen bg-black`;
const CenterCont = tw.div`w-[342px] flex flex-col justify-center items-center gap-8 `;
const ReallyLogo = tw.img`w-[170px] col-start-1 row-start-1 block`;
const HeaderCont = tw.div`flex flex-col items-center gap-1 self-stretch my-[30px]`;
const H2 = tw.h2`text-black text-3xl font-black leading-[1.875rem]`;
const TxtBase = tw.span`text-black text-base font-light leading-4`;
const H2Txt = tw(H2)`font-light text-white text-[36px]`;
const H3Txt = tw(TxtBase)`text-white mt-[10px] text-[18px]`;
const SigninCont = tw.div`flex flex-col items-center gap-6 self-stretch`;
const FooterCont = tw.button`flex justify-center items-center gap-1`;
const FooterTxt = tw(TxtBase)`text-white mt-[4px]`;
const FooterIcon = tw.div`h-5 w-5`;

export const ResetPassword = (props: ResetPasswordProps) => {
  const { onSubmit, footerOptions, isCodeValid, email } = props;

  return (
    <Container>
      <ReallyLogo src={logo} alt="Really Logo" />
      <HeaderCont>
        <H2Txt>reset your password</H2Txt>
        <H3Txt>{`for ${email}`}</H3Txt>
      </HeaderCont>
      <CenterCont>
        <SigninCont>
          {isCodeValid ? <ResetPasswordForm onSubmit={onSubmit} /> : <Spinner color="white" />}
        </SigninCont>
        <FooterCont onClick={footerOptions?.onGoBack}>
          <FooterIcon>
            <ArrowLeftIcon />
          </FooterIcon>
          <FooterTxt>{footerOptions?.title || "back to log in"}</FooterTxt>
        </FooterCont>
      </CenterCont>
    </Container>
  );
};
