import React from "react";
import tw from "twin.macro";
import { Button } from "@components/subscription/Button";
import { WarningIcon } from "@assets/icons/WarningIcon";

interface SkipUnlockProps {
  setOpen: (val: boolean) => void;
  handleSkipUnlock: () => void;
}

const Container = tw.div``;
const ModalTitle = tw.h1`text-black text-[32px] font-light leading-none break-words mb-5 mt-[60px]`;
const Text = tw.p`text-black`;
const IconContainer = tw.div`absolute top-[-62px]`;
const ButtonContainer = tw.div`flex flex-col`;

export const SkipUnlock: React.FC<SkipUnlockProps> = ({ setOpen, handleSkipUnlock }) => {
  return (
    <Container>
      <IconContainer>
        <WarningIcon />
      </IconContainer>
      <ModalTitle>are you sure?</ModalTitle>
      <Text>
        your phone must be unlocked by the carrier in order to activate our service. if you need
        time to check, don't worry, we make it easy for you to come back when you're ready.
      </Text>
      <ButtonContainer>
        <Button
          title="yes, skip this"
          type={"active"}
          isSubmit
          className="lg:w-full mt-[36px]"
          onClick={() => handleSkipUnlock()}
        />
        <Button
          title="no, go back and check"
          type={"text"}
          isSubmit
          className="mt-[16px]"
          onClick={() => setOpen(false)}
        />
      </ButtonContainer>
    </Container>
  );
};
