import React from "react";
import tw from "twin.macro";
import {
  type FieldError,
  type Merge,
  type FieldErrorsImpl,
  type UseFormRegister,
} from "react-hook-form";

import AlertCircle from "@assets/icons/alert-circle.svg";

interface InputFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  register?: UseFormRegister<any>;
  required?: boolean;
  pattern?: any;
  type?: string;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  minLength?: number;
  maxLength?: number;
  max?: number;
  validate?: (value: string) => string;
  className?: string;
}

const messages = {
  required: () => "this field is required",
  minLength: (field: string) => `Your ${field.toLowerCase()} is to short`,
  maxLength: (field: string) => `Your ${field.toLowerCase()} is too long`,
};

const InputContainer = tw.div`w-full flex items-start flex-col mb-[10px] relative`;
const Input = tw.input`w-full h-[61px] rounded border-[1.5px] pl-[16px] text-[18px]`;
const Label = tw.label`w-full text-[16px] mb-[12px]`;
const ErrorMessage = tw.div`w-full text-[13px] text-red-500 mt-[3px]`;
const ErrorAlert = tw.div`absolute top-[41%] right-[20px]`;

export const InputField: React.FC<InputFieldProps> = ({
  label,
  name,
  register,
  required,
  placeholder,
  pattern = {},
  type = "text",
  error,
  value,
  onChange,
  maxLength,
  minLength,
  max,
  validate,
  className,
}) => {
  const inputProps = {
    required: required ? "this field is required." : "",
    pattern,
    validate,
  };

  const registerProps = register ? register(name, inputProps) : {};
  const combinedProps = { ...registerProps, onChange };

  return (
    <InputContainer>
      <Label
        htmlFor={name}
        className={`input-hide-arrows ${error ? "text-red-500" : "text-inherit"}`}
      >
        {label}
      </Label>
      <Input
        type={type}
        id={name}
        placeholder={placeholder}
        autoComplete="true"
        className={`${error ? "border-red-500 pr-[44px]" : "border-black"} ${className}`}
        value={value}
        maxLength={maxLength}
        minLength={minLength}
        max={max}
        {...combinedProps}
      />
      {error && (
        <>
          <ErrorMessage>
            {/* @ts-expect-error conflict with react-form types */}
            {error.message || messages[error.type](label)}
          </ErrorMessage>
          <ErrorAlert className="absolute bottom-[36px] right-[20px]">
            <img src={AlertCircle} />
          </ErrorAlert>
        </>
      )}
    </InputContainer>
  );
};
