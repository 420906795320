import React from "react";
import tw from "twin.macro";
import ATTInstructions from "assets/images/att-port-instructions.png";

const GrayCard = tw.div`w-full bg-[rgba(217, 217, 217, 0.20)] p-[32px] font-[400]`;
const CardTitle = tw.div`text-[24px] mb-[20px]`;
const CardText = tw.div`text-[16px] font-[400]`;
const ImageContainer = tw.div`w-full mt-[20px]`;
const Image = tw.img`w-full`;

export const ATT: React.FC = () => {
  return (
    <GrayCard>
      <CardTitle>how to find your account number:</CardTitle>
      <CardText>1. Go to your myAT&T Profile. Sign in, if asked.</CardText>
      <CardText>
        2. Scroll to My linked accounts. You'll see the account number for each of your services..
      </CardText>
      <ImageContainer>
        <Image src={ATTInstructions} />
      </ImageContainer>
    </GrayCard>
  );
};
